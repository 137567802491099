import React, { useState } from 'react';
import './newSlider.css';

const NewSlider = ({ images }) => {
  const slideCount = images.length;
  const slideWidth = 100 / slideCount;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageSizes, setImageSizes] = useState([]);

  const handlePrevClick = () => {
    // setCurrentIndex((currentIndex - 1 + slideCount) % slideCount);
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

    const handleImageClick = (imageSrc) => {
        window.open(imageSrc, '_blank');
    };

  const handleNextClick = () => {
    // setCurrentIndex((currentIndex + 1) % slideCount);
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-slide" style={{ width: `${slideCount * 100}%`, transform: `translateX(-${currentIndex * slideWidth}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="carousel-image-container" onClick={()=>handleImageClick(image)}>
            <img className="carousel-image" src={image} alt={`Image ${index}`} />
          </div>
        ))}
      </div>

      <div className="carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`carousel-dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>

      <button className="carousel-prev-btn" onClick={handlePrevClick}>&lt;</button>
      <button className="carousel-next-btn" onClick={handleNextClick}>&gt;</button>
    </div>

  );
};

export default NewSlider;

import React, { useEffect, useState } from 'react';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import PhotoCarouselForCarPreview from './PhotoCarouselForCarPreview.jsx';
import { useNavigate } from 'react-router-dom';
import './CarSnapshotPreview.css'

const CarSnapshotPreview = ({ snapshot }) => {

    const [backend, setBackend] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(snapshot)
        const presentedCarBackend = new PresentedCarBackend(snapshot.id);
        presentedCarBackend.initialize().then(() => {
            setBackend(presentedCarBackend);
        });
    }, [snapshot.id]);

    if (!backend) return null;

    const handleClick = () => {
        // console.log('Car snapshot clicked!');
        navigate(`/edit/${snapshot.id}`);
    };


    return (

        <div key={snapshot.id} className="individual-car-snapshot" onClick={handleClick}>
            <div className='individual-car-carousel'>
                <PhotoCarouselForCarPreview presentedCarSnapshotId={snapshot.id} recordImageKeys={backend.carImageList} />
            </div>

            <div className="car-details">
                <div className="car-ymm">
                    <h2>{snapshot.year} {snapshot.make} {snapshot.carModel}</h2>
                </div>


                {/* recorded investment and mileage commented out */}
                {/* <div className="car-summary-group">
                    <div className='car-summary'>
                        <div className="icon-circle-border">
                            <FontAwesomeIcon icon={faSackDollar} size="xl" />
                        </div>
                        <div className="car-summary-text">
                            <span className='grey-text'><h4>Recorded Investment: </h4></span>
                            <div className="detail-container">
                                <p className="unit-sign">$</p>
                                <h5 className="amount">{backend.getTotalInvestment()}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className='car-summary'>
                        <div className="icon-circle-border">
                            <FontAwesomeIcon icon={faRoad} size="xl" />
                        </div>
                        <div className="car-summary-text">
                            <span className='grey-text'><h4>Last recorded at: </h4></span>
                            <div className="detail-container">
                                <h5 className="amount">{backend.getLastRecordMileage()}</h5>
                                <p className="unit-sign">{backend.getMileageUnit()}</p>
                            </div>
                        </div>
                    </div>
                </div> */}


            </div>
        </div>
    )
}

export default CarSnapshotPreview
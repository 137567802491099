import React from 'react';

const TabSelector = ({ activeTab, onTabChange, hasHighlights, recordCount, highlightCount }) => (
    <div className="selector-tab">
        <button 
            className={`tab ${activeTab === 'time-line' ? 'active' : ''}`} 
            onClick={() => onTabChange('time-line')}
        >
            Timeline ({recordCount})
        </button>
        {hasHighlights && 
            <button 
                className={`tab ${activeTab === 'highlights' ? 'active' : ''}`} 
                onClick={() => onTabChange('highlights')}
            >
                Highlights {highlightCount > 1 && `(${highlightCount})`}
            </button>
        }
        
        <button 
            className={`tab ${activeTab === 'title-registration' ? 'active' : ''}`} 
            onClick={() => onTabChange('title-registration')}
        >
            Title and Registration
        </button>
    </div>
);

export default TabSelector;

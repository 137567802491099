import './NewLogin.css';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useParams, useNavigate } from 'react-router-dom';

function NewLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const navigate = useNavigate();
  const { transferId } = useParams();
  const [error, setError] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function handleAuthAction(e) {
    e.preventDefault();

    if (!email) {
      setError('Please enter an email address.');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!password) {
      setError('Please enter a password.');
      return;
    }

    if (isNewUser && password !== confirmedPassword) {
      setError('Passwords do not match');
      return;
    }

    setError('');

    try {
      if (isNewUser) {
        await Auth.signUp({ username: email, password, attributes: { email } });
        setIsVerificationCodeSent(true);
      } else {
        await Auth.signIn(email, password);
        navigate(`/transfer-key/${transferId}`);
      }
    } catch (error) {
      if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
        setError('Incorrect email or password.');
      } else {
        setError(error.message);
      }
    }
  }

  async function handleVerificationSubmit(e) {
    e.preventDefault();

    try {
      await Auth.confirmSignUp(email, verificationCode);
      await Auth.signIn(email, password);
      navigate(`/transfer-key/${transferId}`);
    } catch (error) {
      setError('Error verifying the email: ' + error.message);
    }
  }

  async function handleResendVerificationCode() {
    try {
      if (isNewUser) {
        await Auth.resendSignUp(email);
      } else {
        await Auth.forgotPassword(email);
      }
      setError('Verification code resent successfully.');
    } catch (error) {
      setError('Error resending verification code: ' + error.message);
    }
  }

  async function handleForgotPassword(e) {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      await Auth.forgotPassword(email);
      setIsResettingPassword(true);
    } catch (error) {
      setError('Error sending code: ' + error.message);
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      await Auth.signIn(email, newPassword);
      navigate(`/transfer-key/${transferId}`);
    } catch (error) {
      setError('Error resetting password: ' + error.message);
    }
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>{isResettingPassword ? 'Reset Password' : (isNewUser ? 'Sign Up for Papertrail' : 'Login to Papertrail')}</h2>
        
        {isResettingPassword || isVerificationCodeSent ? (
          <form onSubmit={isResettingPassword ? handleResetPassword : handleVerificationSubmit}>
            <label>Verification Code</label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            
            {isResettingPassword && (
              <>
                <label>New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label>Confirm New Password</label>
                <input
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </>
            )}
            
            <button type="submit">{isResettingPassword ? 'Reset Password' : 'Verify'}</button>
            <button type="button" className="special-btn" onClick={handleResendVerificationCode}>
              Resend Verification Code
            </button>
          </form>
        ) : (
          <form onSubmit={handleAuthAction}>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {setEmail(e.target.value);
                               setError('');}}
            />
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => {setPassword(e.target.value);
                               setError('');}}
            />

            {isNewUser && (
              <>
                <label>Confirm Password</label>
                <input
                  type="password"
                  value={confirmedPassword}
                  onChange={(e) => {setConfirmedPassword(e.target.value);
                                    setError('');}}
                />
              </>
            )}

            <button type="submit">
              {isNewUser ? 'Sign Up' : 'Sign In'}
            </button>

            {!isNewUser && <button className="special-btn" onClick={handleForgotPassword}>Forgot Password?</button>}
          </form>
        )}
        
        <p>
          {isNewUser
            ? 'Already have an account?'
            : "Don't have an account yet?"}{' '}
          <button
            className="toggle-btn"
            onClick={() => setIsNewUser(!isNewUser)}
          >
            {isNewUser ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
        
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default NewLogin;

// // PhotoCarouselComponent.js

import React, { useEffect, useState } from 'react';
import PresentedCarBackend from '../../../backend/PresentedCarBackend.js';
import { API, Storage, graphqlOperation } from "aws-amplify";
import "../DescriptionSection.css";
import ImageCarousel from './ImageCarousel.jsx';
import placeholderImage from '../../resources/pt_logo.png'


function PhotoCarouselComponent({ presentedCarSnapshotId, backend, images }) {
    console.log("backend: ", backend);

    return (
        <div className="photo-carousel-section">
            <ImageCarousel images={images} aspectRatio='4:3' maxWidth='450px'/>
        </div>
    );
}

export default PhotoCarouselComponent;
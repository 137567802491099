import React, { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import PortalBackend from '../../backend/PortalBackend';
import '@aws-amplify/ui-react/styles.css';
import CarSnapshotPreview from './CarSnapshotPreview';
import './PortalMain.css';

const PortalMainPage = ({ user, signOut }) => {
  const [backend, setBackend] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  // Add a loading state

  useEffect(() => {
    const portalBackend = new PortalBackend(user.username);
    portalBackend.initialize()
      .then(() => {
        setBackend(portalBackend);
      })
      .finally(() => {
        setIsLoading(false);  // Set loading to false when initialization is complete
      });
  }, [user.username]);

  if (isLoading) {
    return <div className="loading-screen"><div className="spinner"></div></div>;
  }

  if (!backend) return null;

 // Check if carSnapshots array is empty
 if (backend.carSnapshots.length === 0) {
  return <div className="no-cars-message">No presented car to display - please start your presentations from Papertrail app!</div>;
}

return (
  <div className="car-snapshots">
    {backend.carSnapshots.map((carSnapshot, index) => (
      <CarSnapshotPreview snapshot={carSnapshot} key={index} />
    ))}
  </div>
);
}

export default withAuthenticator(PortalMainPage);
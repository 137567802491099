import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import placeholderImage from '../components/resources/pt_logo.png'
import * as mutations from '../graphql/mutations';



// This file works as a backend for the Presentation component.

/*
Usage:
    let presentedCarSnapshotId = "aaa-bbb-ccc"
    if (presentedCarSnapshotId) {
        const presentedCarBackend = new PresentedCarBackend(presentedCarSnapshotId);

        presentedCarBackend.initialize().then(() => {
            console.log("showTotalInvestment: " + presentedCarBackend.showTotalInvestment);
            console.log("showMileage: " + presentedCarBackend.showMileage);
        });
        
    }
*/

class PresentedCarBackend {

    constructor(presentedCarSnapshotId) {

        // The ID of the PresentedCarSnapshot provided by the URL
        this.presentedCarSnapshotId = presentedCarSnapshotId;

        // The PresentedCarSnapshot object fetched from AppSync
        this.snapshot = null;


        // The following fields are fetched from the PresentedCarSnapshot and PresentedCarRecordSnapshots
        this.showTotalInvestment = false;
        this.showMileage = false;
        this.showDoneByForEachRecord = false;
        this.showOverallDescription = false;

        this.totalHistoryCount = 0;
        this.totalImagesCount = 0;
        this.totalInvestment = 0;
        this.lastRecordMileage = 0;

        this.overallDescription = "";
        this.mileageUnit = "";
        this.primaryRecordListMode = -1;

        this.carImageList = [];

        this.recordsAndStories = [];

        this.datedRecordsDescending = [];
        this.datedRecordsAscending = [];
        this.undatedRecords = [];

        this.highlightedRecords = [];
        this.hasHighlightedRecords = false;
    }

    /// METHODS /// 

    // Initialize the backend and its fields
    async initialize() {
        await this.fetchSnapshot();

        this.recordsAndStories = await this.getRecordsAndStories(this.presentedCarSnapshotId);

        this.highlightedRecords = this.getHighlightedRecords();



        this.showTotalInvestment = this.getShowTotalInvestment();
        this.showMileage = this.getShowMileage();
        this.showDoneByForEachRecord = this.getShowDoneByForEachRecord();
        this.showOverallDescription = this.getShowOverallDescription();

        this.totalHistoryCount = this.getTotalHistoryCount();
        this.totalImagesCount = this.getTotalImagesCount();
        this.totalInvestment = this.getTotalInvestment();
        this.lastRecordMileage = this.getLastRecordMileage();

        this.overallDescription = this.getOverallDescription();
        this.mileageUnit = this.getMileageUnit();
        this.primaryRecordListMode = this.getPrimaryRecordListMode();
        this.carImageList = this.getCarImageList();



        this.datedRecordsDescending = this.getDatedRecordsDescending();
        this.datedRecordsAscending = this.getDatedRecordsAscending();
        this.undatedRecords = this.getUndatedRecords();

        this.hasHighlightedRecords = this.getHasHighlightedRecords();

    }

    // Helper method to fetch the PresentedCarSnapshot by ID
    async fetchSnapshot() {
        let isLoggedIn = await this.isLoggedIn();
        // console.log("is logged in: " + isLoggedIn)

        if (!this.snapshot) {
            try {
                const result = await API.graphql({
                    ...graphqlOperation(queries.getPresentedCarSnapshot, { id: this.presentedCarSnapshotId }),
                    authMode: isLoggedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
                });
                this.snapshot = result.data.getPresentedCarSnapshot;
            } catch (error) {
                console.error("Error fetching PresentedCarSnapshot:", error);
            }
        }
    }


    // Helper method for fetching a list of records and stories associated with the car
    async getRecordsAndStories(presentedCarSnapshotId, nextToken = null) {
        let recordsAndStories = [];
        let isLoggedIn = await this.isLoggedIn();
        // console.log("is logged in: " + isLoggedIn)

        try {
            const variables = {
                limit: 100,
                filter: {
                    presentedcarsnapshotID: { eq: presentedCarSnapshotId }
                },
                nextToken: nextToken
            };

            const result = await API.graphql({
                ...graphqlOperation(queries.listPresentedCarRecordSnapshots, variables),
                authMode: isLoggedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            });

            const items = result.data.listPresentedCarRecordSnapshots.items;

            recordsAndStories.push(...items);  // Use spread operator to push individual items to the list

            // If a nextToken is present, make a recursive call
            if (result.data.listPresentedCarRecordSnapshots.nextToken) {
                const moreRecordsAndStories = await this.getRecordsAndStories(presentedCarSnapshotId, result.data.listPresentedCarRecordSnapshots.nextToken);
                recordsAndStories = recordsAndStories.concat(moreRecordsAndStories);  // Concatenate results of the recursive call to the current list
            }

        } catch (error) {
            console.error("Error fetching PresentedCarRecordSnapshots count:", error);
        }

        return recordsAndStories;
    }

    // Return the showInvestment field of the PresentedCarSnapshot.
    getShowTotalInvestment() {
        return this.snapshot.showInvestment === null ? true : this.snapshot.showInvestment;
    }

    //Return the showMileage field of the PresentedCarSnapshot. 
    //If this is false, then do not show both the mileage of each record, and total mileage.
    getShowMileage() {
        return this.snapshot.showMileage === null ? true : this.snapshot.showMileage;
    }

    //Return the done by field for each record. If false, do not show the done by field of each records.
    getShowDoneByForEachRecord() {
        return this.snapshot.showDoneBy === null ? true : this.snapshot.showDoneBy;
    }

    getShowOverallDescription() {
        return this.snapshot.showOverallDescription === null ? true : this.snapshot.showOverallDescription;
    }


    //Total number of histories associated with the car. 
    // This includes stories and records.
    //In our application, this should just be the count of presentedCarRecordSnapshots associated with the presentedCarSnapshot.
    getTotalHistoryCount() {
        return this.recordsAndStories.length;
    }

    //Total number of images associated with all the records and stories and the car. (updated to add car images)
    getTotalImagesCount() {
        let totalImagesCar = this.snapshot.picKeyList ? this.snapshot.picKeyList.split(",").filter((key) => key).length : 0;
        var totalImagesCountRecordsAndStories = 0;
        for (var i = 0; i < this.recordsAndStories.length; ++i) {
            totalImagesCountRecordsAndStories += this.recordsAndStories[i].picKeyList.split(",").filter((key) => key).length;
        }
        return totalImagesCar + totalImagesCountRecordsAndStories;
    }

    //Total investment associated with the car. Calculated in the same fashion as the app’s car page.
    getTotalInvestment() {
        var result = 0;
        for (var i = 0; i < this.recordsAndStories.length; ++i) {
            const parsedCost = parseFloat(this.recordsAndStories[i].Cost);
            if (!isNaN(parsedCost)) {
                result += parsedCost;
            }
        }
        const formattedResult = result.toFixed(2);
        return formattedResult;
    }

    //Last record’s mileage. Calculated in the same fashion as the app’s car page.
    getLastRecordMileage() {
        var result = 0;
        for (var i = 0; i < this.recordsAndStories.length; ++i) {
            const parsedMileage = parseFloat(this.recordsAndStories[i].CurrMileage);
            if (!isNaN(parsedMileage)) {
                if (parsedMileage > result) {
                    result = parsedMileage;
                }
            }
        }
        const formattedResult = result.toFixed(2);
        return formattedResult;
    }

    //Return the description within the presentedCarSnapshot.
    getOverallDescription() {
        return this.snapshot.overallDescription ? this.snapshot.overallDescription : "";
    }

    //Returns either “mi” or “km” depending on what’s specified in the presentedCarSnapshot.
    getMileageUnit() {
        if (this.snapshot.odometerInMiles == null) {
            return "mi";
        }
        else {
            return this.snapshot.odometerInMiles ? "mi" : "km";
        }
    }

    //User-configured. Decides what page, among undated records, timeline, and title & registration to show. 
    //Default value is -1 or null, and whichever category between undated or timeline has more total images is shown first automatically. 
    //0 = show timeline
    getPrimaryRecordListMode() {
        return this.snapshot.primaryRecordListMode ? this.snapshot.primaryRecordListMode : -1;
    }

    // get list of images from picKeyList field
    // if no image, then return papertrail placeholder image
    getCarImageList() {
        if (this.snapshot.picKeyList) {
            return this.snapshot.picKeyList.split(",").filter((key) => key);
        }
        else {
            return [placeholderImage];
        }
    }

    // Return the list of dated records, return empty list if there is no dated records
    // Each element (could be a list or JSON) in the list is an object that contains the following fields for each record:
    // Title, Date, Mileage, Investment, Description, Done By, List of Images, isHighlighted (in the format that can be used by the Carousel component)
    // If a record only have date or mileage, then the other field should be N/A, and sort the record with the available field
    // The list should be sorted by date in descending order, if two records have the same date, then sort by mileage in descending order
    getDatedRecordsDescending() {
        const dated = this.recordsAndStories.filter(record => record.noTime !== true || record.CurrMileage);
        const formattedRecords = dated.map(record => {

            let formattedRecordCost;
            const parsedCost = parseFloat(record.Cost);
            if (!isNaN(parsedCost)) {
                formattedRecordCost = parsedCost.toFixed(2);
            }
            else {
                formattedRecordCost = "N/A";
            }

            let formattedMileage;
            const parsedMileage = parseFloat(record.CurrMileage);
            if (!isNaN(parsedMileage)) {
                formattedMileage = parsedMileage.toFixed(2);
            }
            else {
                formattedMileage = "N/A";
            }

            return {
                id: record.id,
                title: record.title ? record.title : "",
                date: record.noTime !== true ? record.recordDate.replaceAll("/", "-") : "N/A",
                noTime: record.noTime,
                mileage: formattedMileage,
                investment: formattedRecordCost,
                description: record.description ? record.description : "N/A",
                doneBy: record.whoDidTheJob ? record.whoDidTheJob : "N/A",
                images: record.picKeyList ? record.picKeyList.split(",").filter((key) => key) : [placeholderImage],
                isHighlighted: this.highlightedRecords.some(highlightedRecord => highlightedRecord.id === record.id)
            };
        });

        if (formattedRecords.length === 0) {
            return this.getUndatedRecords();
        }

        let withDateAndMileage = formattedRecords.filter(record => record.date !== 'N/A' && record.mileage !== 'N/A');
        let withDateOnly = formattedRecords.filter(record => record.date !== 'N/A' && record.mileage === 'N/A');
        let withMileageOnly = formattedRecords.filter(record => record.date === 'N/A' && record.mileage !== 'N/A');


        // 1. Sort withDateAndMileage by date
        withDateAndMileage.sort((a, b) => {
            const dateComparison = new Date(b.date) - new Date(a.date);
            if (dateComparison === 0) {
                return parseFloat(b.mileage) - parseFloat(a.mileage); // Notice the order of b and a for descending order
            }
            return dateComparison;
        });

        // Sort withDateOnly by date
        withDateOnly.sort((a, b) => new Date(b.date) - new Date(a.date));

        // 2. Merge withDateAndMileage and withDateOnly while preserving order
        let sortedByDate = [];
        while (withDateAndMileage.length || withDateOnly.length) {
            if (!withDateOnly.length ||
                (withDateAndMileage.length &&
                    new Date(withDateAndMileage[0].date) >= new Date(withDateOnly[0].date))) {
                sortedByDate.push(withDateAndMileage.shift());
            } else {
                sortedByDate.push(withDateOnly.shift());
            }
        }

        // 3. Position the records from withMileageOnly into sortedByDate
        withMileageOnly.sort((a, b) => parseFloat(b.mileage) - parseFloat(a.mileage)).forEach(mileageRecord => {
            let inserted = false;

            for (let i = 0; i < sortedByDate.length; i++) {
                if (sortedByDate[i].mileage !== 'N/A' && parseFloat(sortedByDate[i].mileage) < parseFloat(mileageRecord.mileage)) {
                    sortedByDate.splice(i, 0, mileageRecord);
                    inserted = true;
                    break;
                }
            }

            // If not inserted yet, add to the end
            if (!inserted) {
                sortedByDate.push(mileageRecord);
            }
        });

        return sortedByDate.concat(this.getUndatedRecords());
    }

    // Return the list of dated records, return empty list if there is no dated records
    // Each element (could be a list or JSON) in the list is an object that contains the following fields for each record:
    // Title, Date, Mileage, Investment, Description, Done By, List of Images, isHighlighted (in the format that can be used by the Carousel component)
    // If a record only have date or mileage, then the other field should be N/A, and sort the record with the available field
    // The list should be sorted by date in ascending order, if two records have the same date, then sort by mileage in ascending order
    getDatedRecordsAscending() {
        const dated = this.recordsAndStories.filter(record => record.noTime !== true || record.CurrMileage);
        const formattedRecords = dated.map(record => {

            let formattedRecordCost;
            const parsedCost = parseFloat(record.Cost);
            if (!isNaN(parsedCost)) {
                formattedRecordCost = parsedCost.toFixed(2);
            }
            else {
                formattedRecordCost = "N/A";
            }

            let formattedMileage;
            const parsedMileage = parseFloat(record.CurrMileage);
            if (!isNaN(parsedMileage)) {
                formattedMileage = parsedMileage.toFixed(2);
            }
            else {
                formattedMileage = "N/A";
            }

            return {
                id: record.id,
                title: record.title ? record.title : "",
                date: record.noTime !== true ? record.recordDate.replaceAll("/", "-") : "N/A",
                noTime: record.noTime,
                mileage: formattedMileage,
                investment: formattedRecordCost,
                description: record.description ? record.description : "N/A",
                doneBy: record.whoDidTheJob ? record.whoDidTheJob : "N/A",
                images: record.picKeyList ? record.picKeyList.split(",").filter((key) => key) : [placeholderImage],
                isHighlighted: this.highlightedRecords.some(highlightedRecord => highlightedRecord.id === record.id)
            };
        });

        if (formattedRecords.length === 0) {
            return this.getUndatedRecords();
        }

        let withDateAndMileage = formattedRecords.filter(record => record.date !== 'N/A' && record.mileage !== 'N/A');
        let withDateOnly = formattedRecords.filter(record => record.date !== 'N/A' && record.mileage === 'N/A');
        let withMileageOnly = formattedRecords.filter(record => record.date === 'N/A' && record.mileage !== 'N/A');


        // 1. Sort withDateAndMileage by date (ascending)
        withDateAndMileage.sort((a, b) => {
            const dateComparison = new Date(a.date) - new Date(b.date);
            if (dateComparison === 0) {
                return parseFloat(a.mileage) - parseFloat(b.mileage);
            }
            return dateComparison;
        });

        // Sort withDateOnly by date (ascending)
        withDateOnly.sort((a, b) => new Date(a.date) - new Date(b.date));

        // 2. Merge withDateAndMileage and withDateOnly while preserving order
        let sortedByDate = [];
        while (withDateAndMileage.length || withDateOnly.length) {
            if (!withDateOnly.length ||
                (withDateAndMileage.length &&
                    new Date(withDateAndMileage[0].date) <= new Date(withDateOnly[0].date))) {
                sortedByDate.push(withDateAndMileage.shift());
            } else {
                sortedByDate.push(withDateOnly.shift());
            }
        }

        // 3. Position the records from withMileageOnly into sortedByDate (ascending by mileage)
        withMileageOnly.sort((a, b) => parseFloat(a.mileage) - parseFloat(b.mileage)).forEach(mileageRecord => {
            let inserted = false;

            for (let i = 0; i < sortedByDate.length; i++) {
                if (sortedByDate[i].mileage !== 'N/A' && parseFloat(sortedByDate[i].mileage) > parseFloat(mileageRecord.mileage)) {
                    sortedByDate.splice(i, 0, mileageRecord);
                    inserted = true;
                    break;
                }
            }

            // If not inserted yet, add to the end
            if (!inserted) {
                sortedByDate.push(mileageRecord);
            }
        });

        return sortedByDate.concat(this.getUndatedRecords());
    }

    // Return the list of undated records, return empty list if there is no undated records
    // Each element (could be a list or JSON) in the list is an object that contains the following fields for each record:
    // Title, Combine Date and Mileage as N/A, Investment, Description, Done By, Images, isHighlighted
    getUndatedRecords() {
        const undated = this.recordsAndStories.filter(record => record.noTime === true && !record.CurrMileage);
        const formattedRecords = undated.map(record => {

            let formattedRecordCost;
            const parsedCost = parseFloat(record.Cost);
            if (!isNaN(parsedCost)) {
                formattedRecordCost = parsedCost.toFixed(2);
            }
            else {
                formattedRecordCost = "N/A";
            }

            let formattedMileage;
            const parsedMileage = parseFloat(record.CurrMileage);
            if (!isNaN(parsedMileage)) {
                formattedMileage = parsedMileage.toFixed(2);
            }
            else {
                formattedMileage = "N/A";
            }

            return {
                id: record.id,
                title: record.title ? record.title : "",
                date: "N/A",
                noTime: record.noTime,
                mileage: formattedMileage,
                investment: formattedRecordCost,
                description: record.description ? record.description : "N/A",
                doneBy: record.whoDidTheJob ? record.whoDidTheJob : "N/A",
                images: record.picKeyList ? record.picKeyList.split(",").filter((key) => key) : [placeholderImage],
                isHighlighted: this.highlightedRecords.some(highlightedRecord => highlightedRecord.id === record.id)
            };
        });

        return formattedRecords;
    }


    // Return title and registration information
    // Return text "Coming soon" for now
    getTitleAndRegistration() {
        return "Coming soon!";
    }

    async isLoggedIn() {
        // Another way to get if its a guest or not
        //return await Auth.Credentials.getCredSource() === "guest"
        try {
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }

    // Upon form submission, update the presented car snapshot information
    async updateCarSnapshotInformation(formData) {
        try {
            const input = {
                id: this.presentedCarSnapshotId,
                _version: this.snapshot._version,
                year: formData.year,
                make: formData.make,
                carModel: formData.carModel,
                overallDescription: formData.overallDescription,
            };


            const result = await API.graphql({
                query: mutations.updatePresentedCarSnapshot,
                variables: { input: input }
            });

            this.snapshot = result.data.updatePresentedCarSnapshot;;


            console.log('Update result:', result);
        } catch (error) {
            console.error("Error updating PresentedCarSnapshot:", error);
        }
    }

    // Upon form submission, update the record snapshot information
    async updateRecordSnapshotInformation(formData, recordID) {
        try {

            let recordhasNoTime = formData.date === "N/A";
   

            // If the record has no time, then use today's date
            const today = new Date();
            const todayMonth = today.getMonth() + 1; // getMonth() is zero-based
            const todayDay = today.getDate();
            const todayYear = today.getFullYear();            
            // Convert month and day to string and remove leading 0 if present
            const formattedMonth = todayMonth < 10 ? todayMonth.toString() : todayMonth;
            const formattedDay = todayDay < 10 ? todayDay.toString() : todayDay;
            const formattedToday = `${formattedMonth}/${formattedDay}/${todayYear}`;
     
            // If the record has a time, then use the date provided by the user
            const [year, month, day] = formData.date.split('-');
            let formattedDate = `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`;

            const targetRecordSnapshot = this.recordsAndStories.find(r => r.id === recordID);

            const input = {
                id: targetRecordSnapshot.id,
                _version: targetRecordSnapshot._version,
                title: formData.title,
                whoDidTheJob: formData.doneBy === "N/A" ? "" : formData.doneBy,
                CurrMileage: formData.mileage === "N/A" ? "" : formData.mileage,
                recordDate: recordhasNoTime ? formattedToday : formattedDate,
                Cost: formData.investment === "N/A" ? "" : formData.investment,
                description: formData.description === "N/A" ? "" : formData.description,
                noTime: recordhasNoTime,
                // isHighlighted: formData.isHighlighted,
            };

    
            if (formData.isHighlighted === true) {
                await this.addHighlightedRecord(recordID)                
            }
            else {
                await this.removeHighlightedRecord(recordID)
            }

            const result = await API.graphql({
                query: mutations.updatePresentedCarRecordSnapshot,
                variables: { input: input },
            });


            Object.assign(targetRecordSnapshot, result);


            console.log('Update result:', result);
        } catch (error) {
            console.error("Error updating PresentedCarRecordSnapshot:", error);
        }
    }


    // Upon form submission, update the visibility options of the PresentedCarSnapshot
    async updateVisibilityOptions(formData) {
        try {
            const input = {
                id: this.presentedCarSnapshotId,
                _version: this.snapshot._version,
                showInvestment: formData.showTotalInvestment,
                showMileage: formData.showMileage,
                showDoneBy: formData.showDoneByForEachRecord,
                showOverallDescription: formData.showOverallDescription
            };

            const result = await API.graphql({
                query: mutations.updatePresentedCarSnapshot,
                variables: { input: input },
            });



            console.log('Update result:', result);

            this.snapshot = result.data.updatePresentedCarSnapshot;


        } catch (error) {
            console.error("Error updating PresentedCarRecordSnapshot:", error);
        }
    }




    /// HIGHLIGHTED RECORDS RELATED METHODS ///

    //returns whether or not the car has a list of highlighted records
    getHasHighlightedRecords() {
        return this.highlightedRecords.length > 0;
    }


    // Retrieve the list of highlighted records from PresentedCarSnapshot
    // return type: list of frontend-ready JSON objects
    getHighlightedRecords() {
        let highlightedRecords = this.snapshot.highlightedRecords || [];


        const formattedRecords = highlightedRecords.map(recordID => {

            const record = this.recordsAndStories.find(r => r.id === recordID)

            let formattedRecordCost;
            const parsedCost = parseFloat(record.Cost);
            if (!isNaN(parsedCost)) {
                formattedRecordCost = parsedCost.toFixed(2);
            }
            else {
                formattedRecordCost = "N/A";
            }

            let formattedMileage;
            const parsedMileage = parseFloat(record.CurrMileage);
            if (!isNaN(parsedMileage)) {
                formattedMileage = parsedMileage.toFixed(2);
            }
            else {
                formattedMileage = "N/A";
            }

            return {
                id: record.id,
                title: record.title ? record.title : "",
                date: record.noTime !== true ? record.recordDate.replaceAll("/", "-") : "N/A",
                noTime: record.noTime,
                mileage: formattedMileage,
                investment: formattedRecordCost,
                description: record.description ? record.description : "N/A",
                doneBy: record.whoDidTheJob ? record.whoDidTheJob : "N/A",
                images: record.picKeyList ? record.picKeyList.split(",").filter((key) => key) : [placeholderImage],
                isHighlighted: true
            };
        });

        // console.log(formattedRecords)
        return formattedRecords;

    }

    // Given an ID of PresentedRecordSnapshot, update the highlightedList field of the PresentedCarSnapshot
    async addHighlightedRecord(recordIdToAdd) {
        try {
            // Step 1: Fetch the current highlighted record IDs
            let currentHighlightedRecordIDs = this.snapshot.highlightedRecords || [];


            // Check if recordIdToAdd is already in the list
            if (currentHighlightedRecordIDs.includes(recordIdToAdd)) {
                // console.log("Record already highlighted. No update needed.");
                return this.snapshot; 
            }

            // Step 2: Append the new record ID to the list
            currentHighlightedRecordIDs = [...currentHighlightedRecordIDs, recordIdToAdd];


            const input = {
                id: this.presentedCarSnapshotId,
                _version: this.snapshot._version,
                highlightedRecords: currentHighlightedRecordIDs
            };

            const result = await API.graphql({
                query: mutations.updatePresentedCarSnapshot,
                variables: { input: input },
            });

            this.snapshot = result.data.updatePresentedCarSnapshot;
            this.highlightedRecords = this.getHighlightedRecords();
        
            return result.data.updatePresentedCarSnapshot;

        } catch (error) {
            console.error('Error updating highlighted records:', error);
            throw error;
        }
    };

    // Given an ID of PresentedRecordSnapshot, update the highlightedList field of the PresentedCarSnapshot
    async removeHighlightedRecord(recordIdToRemove) {
        try {
            // Step 1: Fetch the current highlighted record IDs
            let currentHighlightedRecordIDs = this.snapshot.highlightedRecords || [];
        
            // Check if recordIdToRemove is in the list
            if (!currentHighlightedRecordIDs.includes(recordIdToRemove)) {
                return this.snapshot; 
            }
    
            // Step 2: Remove the record ID from the list
            currentHighlightedRecordIDs = currentHighlightedRecordIDs.filter(id => id !== recordIdToRemove);
        
            const input = {
                id: this.presentedCarSnapshotId,
                _version: this.snapshot._version,
                highlightedRecords: currentHighlightedRecordIDs
            };
    
            const result = await API.graphql({
                query: mutations.updatePresentedCarSnapshot,
                variables: { input: input },
            });
    
            this.snapshot = result.data.updatePresentedCarSnapshot;
            this.highlightedRecords = this.getHighlightedRecords();

            return result.data.updatePresentedCarSnapshot;
    
        } catch (error) {
            console.error('Error updating highlighted records:', error);
            throw error;
        }
    };



}

export default PresentedCarBackend;
import React, { useState, useEffect, useRef } from 'react';
import './ExpandableParagraph.css';

function ExpandableParagraph({ textContent }) {
  // State to hold the expand/collapse status
  const [isExpanded, setIsExpanded] = useState(false);
  // State to check if the content exceeds the maximum height
  const [isExpandable, setIsExpandable] = useState(false);
  // Ref to measure the paragraph
  const contentRef = useRef(null);

  // Effect to set isExpandable once the component mounts and when content changes
  useEffect(() => {
    if (contentRef.current.offsetHeight >= 100) {
      setIsExpandable(true);
    } else {
      setIsExpandable(false);
    }
  }, [textContent]); // Depend on textContent so it runs again if text changes

  // console.log("Here's the isExpandable value: ", isExpandable);

  // Function to toggle expand/collapse
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='text-container'>
        <div className={`text-paragraph ${isExpanded ? 'expanded' : ''}`} ref={contentRef}>
            {textContent}
        </div>
        {isExpandable && (
            <button onClick={toggleExpansion}>
                {isExpanded ? 'Collapse' : 'Expand'}
            </button>
        )}
    </div>
    
  );
}

export default ExpandableParagraph;

import React, { useState, useEffect } from 'react';
import TabSelector from './TabSelector';
import IndividualRecordsComponent from './IndividualRecordComponent';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import './RecordSection.css';

function RecordSection({ presentedCarSnapshotId, hightlightRecords, timelineRecords, mileageUnit, titleAndRegistration, hasHighlightedRecords, backend, isEdit}) {
    const [activeTab, setActiveTab] = useState('time-line');  
    console.log("hasHighlightedRecords in record section: ", hasHighlightedRecords);

    useEffect(() => {
            setActiveTab(hasHighlightedRecords ? 'highlights' : 'time-line');
    }, [hasHighlightedRecords]);

    console.log("timelineRecords: ", timelineRecords);

    if (!backend) return null;

    return (
        <div className="record-section">
            <TabSelector activeTab={activeTab} onTabChange={setActiveTab} hasHighlights={backend.getHasHighlightedRecords()} recordCount={backend.datedRecordsDescending.length} highlightCount={backend.highlightedRecords.length} />
            {/* Depending on the activeTab, you can render the respective records */}
            {activeTab === 'time-line' && <IndividualRecordsComponent presentedCarSnapshotId={presentedCarSnapshotId} records={timelineRecords} mileageUnit={mileageUnit} backend={backend} isEdit={isEdit} />}
            {hasHighlightedRecords && activeTab === 'highlights' && <IndividualRecordsComponent presentedCarSnapshotId={presentedCarSnapshotId} records={hightlightRecords} mileageUnit={mileageUnit} backend={backend} isEdit={isEdit} />}
            {activeTab === 'title-registration' && titleAndRegistration}
        </div>
    );
}

export default RecordSection;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PresentationTop from './presentationMVP/PresentationTop.js';
import PageDivider from './presentationMVP/PageDivider.js';
import Records from './presentationMVP/Records.js';
import DescriptionSection from './DescriptionSection.js';
import RecordSection from './RecordSection.js';
import './Presentation.css';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import placeholderImage from '../resources/pt_logo.png';
import { Storage } from "aws-amplify";

const Presentation = ({ isEdit }) => {
    const { presentedCarSnapshotId } = useParams();
    const [backend, setBackend] = useState(null);
    const [images, setImages] = useState([]);
    const [highlightRecords, setHighlightRecords] = useState([]);
    const [timelineRecords, setTimelineRecords] = useState([]);
    const [mileageUnit, setMileageUnit] = useState(null);
    const [titleAndRegistration, setTitleAndRegistration] = useState(null);
    const [hasHighlightedRecords, setHasHighlightedRecords] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading

            const presentedCarBackend = new PresentedCarBackend(presentedCarSnapshotId);
            await presentedCarBackend.initialize();

            // Set backend and related data
            setBackend(presentedCarBackend);
            setHighlightRecords(presentedCarBackend.highlightedRecords);
            setTimelineRecords(presentedCarBackend.datedRecordsAscending);
            setMileageUnit(presentedCarBackend.getMileageUnit());
            setTitleAndRegistration(presentedCarBackend.getTitleAndRegistration());
            setHasHighlightedRecords(presentedCarBackend.getHasHighlightedRecords());

            // Fetch images
            const imageKeys = presentedCarBackend.getCarImageList();
            if (imageKeys.length === 1 && imageKeys[0] === placeholderImage) {
                console.log("Setting placeholder image");
                setImages([{ url: placeholderImage }]);
            } else {
                const fetchedImages = await Promise.all(
                    imageKeys.map(async (key) => {
                        const imageUrl = await Storage.get(key, {
                            expires: 3600,
                            level: "public",
                            download: false,
                            contentType: "image/jpeg",
                        });
                        return { key, url: imageUrl };
                    })
                );
                setImages(fetchedImages);
            }

            // Stop loading once data is fetched
            setLoading(false);
        };

        if (presentedCarSnapshotId) {
            fetchData();
        }
    }, [presentedCarSnapshotId]);

    const [order, setOrder] = useState('ASC');
    console.log("backend in main page: ", backend);
    console.log("presentedCarSnapshotId in main page: ", presentedCarSnapshotId);

    if (loading) {
        return (
            <div className="loading-container">
                <p>Loading...</p>
                <div className="spinner"></div> {/* This div should have the "spinner" class */}
            </div>
        );
    }


    if (presentedCarSnapshotId) {
        return (
            <div className='presentation'>
                <DescriptionSection
                    presentedCarSnapshotId={presentedCarSnapshotId}
                    backend={backend}
                    images={images}
                    isEdit={isEdit}
                />
                <RecordSection
                    presentedCarSnapshotId={presentedCarSnapshotId}
                    highlightRecords={highlightRecords}
                    timelineRecords={timelineRecords}
                    mileageUnit={mileageUnit}
                    titleAndRegistration={titleAndRegistration}
                    hasHighlightedRecords={hasHighlightedRecords}
                    backend={backend}
                    isEdit={isEdit}
                />
            </div>
        );
    } else {
        window.location.replace('https://www.papertrailauto.com/');
        return null;
    }
};

export default Presentation;

import * as React from "react";
import "./Transfer.css";
import CarDescription from "./CarDescription.js";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Amplify, API, Hub } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { Transfer } from "../../models";
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import NewLogin from "./NewLogin.js";

async function submitTransfer(transferId, key, navigate) {
  const apiName = "transferExecutor"; // replace this with your api name.
  const path = "/transferExecutor"; //replace this with the path you have configured on your API
  const myInit = {
    body: {
      transferId: transferId,
      passphrase: key
    },
  };

  API.post(apiName, path, myInit)
    .then((response) => {
      navigate("/transfer-success")
    })
    .catch((error) => {
      console.log(error.response);
      navigate("/transfer-fail")
    });
}

function TransferPage() {
  let navigate = useNavigate();
  let { transferId } = useParams();
  const [transfer, setTransfer] = React.useState(null);

  React.useEffect(() => { 

    const queryTransfer = async () => {
      const transferInfo = await DataStore.query(Transfer, transferId);
      setTransfer(transferInfo);
      console.log(transferInfo);
      if (transferInfo.isDone) {
        navigate("/transfer-fail");
      }
    }
    
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;
 
      console.log("DataStore event", event, data);
 
      if (event === "ready") {
        const transferInfo = await DataStore.query(Transfer, transferId);
        // "refresh" the transfer info and the page
        // in any funtional component, you can use the state to trigger a re-render
        setTransfer(transferInfo);
        console.log(transferInfo);
        if (transferInfo.isDone) {
          navigate("/transfer-fail");
        }
      }

    });
 
    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();
    queryTransfer();
 
    return () => {
      removeListener();
    };
  }, []);
  const [key, setKey] = React.useState("");
  return (
    transfer && 
    <div>
      {/* <Authenticator> */}
        <div className="intro">
          <h3> Just to make sure... You accept the {transfer.carYear} {transfer.carMake} {transfer.carModel}</h3>
        </div>
        <CarDescription {...transfer} />
        <div className="disclaimer">
          Please click the button below to accept the transfer!
        </div>
        <div className="auth">
            <div className="email-key">
              <TextField
                required
                className="email-key"
                label="Key"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                variant="standard"
              />
            </div>
            <div className="email-key">
              <Button className="email-key" id='mineButton' variant="contained" onClick={() => submitTransfer(transferId, key, navigate)}>
                It's Mine Now!
              </Button>
            </div>
        </div>
        <div className="bottomSpace">
        </div>
      {/* </Authenticator> */}
    </div>
  );
}

export default TransferPage;

import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import './LoginPage.css';
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

const LoginPage = () => {
  const [loginusername, setLoginUsername] = useState("");
  const [loginpassword, setLoginPassword] = useState("");
  const [signupusername, setSignupUsername] = useState("");
  const [signuppassword, setSignupPassword] = useState("");
  const navigate = useNavigate();
  const { transferId } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
    //   await Auth.signIn(loginusername, loginpassword);
      navigate(`/transfer-key/${transferId}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await Auth.signUp({
        signupusername,
        signuppassword,
      });
      navigate("/confirm-signup");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSignOut = async (e) => {
    e.preventDefault();
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <div className="auth">
      <Authenticator>
        <form onSubmit={handleSignOut}>
            <button type="submit">Log out</button>
        </form>
      </Authenticator>
      {/* <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={loginusername}
          onChange={(e) => setLoginUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={loginpassword}
          onChange={(e) => setLoginPassword(e.target.value)}
        />
        <button type="submit">Log In</button>
      </form>

      <h1>Sign Up</h1>
      <form onSubmit={handleSignUp}>
        <input
          type="text"
          placeholder="Username"
          value={signupusername}
          onChange={(e) => setSignupUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={signuppassword}
          onChange={(e) => setSignupPassword(e.target.value)}
        />
        <button type="submit">Sign Up</button>
      </form> */}
    </div>
  );
};

export default LoginPage;

// simple react component tha renders a div with a checkmark, some text, and a button to download an app
import * as React from "react";
import "./Transfer.css";
import Button from '@mui/material/Button';
import Clear from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";

async function goBack(navigate) {
    navigate(-1);
}

const TransferFailure = (props) => {
    const navigate = useNavigate();
  return (
    <div className="statusContainer">
        <div>
            <Clear sx={{ fontSize: 150 }} />
        </div>
        <div className="transfer-success-text">
            <div className="transfer-success-text-header">Transfer Failed</div>
            <div className="transfer-success-text-body">Your car was not able to be transferred.</div>
        </div>
        <div className="email-key">
            <Button className="email-key" variant="contained">Download App</Button>
        </div>
        <div className="email-key">
            <Button className="email-key" variant="outlined" onClick={() => goBack(navigate)}>Go Back</Button>
        </div>
        
    </div>
    );
};
export default TransferFailure;

import React, {Component} from "react";
import "./PageDivider.css"
import { DataStore } from 'aws-amplify';
import { PresentedCarRecordSnapshot } from "../../../models";
import { SortDirection } from "@aws-amplify/datastore";
import 'react-awesome-slider/dist/styles.css';
import { Hub } from "@aws-amplify/core";

class PageDivider extends Component {
    constructor(props) {
      super(props);
  
      this.handleSelect = this.handleSelect.bind(this);
      this.state = {
        loading: true,
        recordNum: 0,
      }
    }
  
    handleSelect(e) {
      this.props.setOrder(e.target.value);
    }

    async fetchRecords() {
        const { order, setOrder, presentedCarSnapshotId } = this.props;
        try {
        const records = await DataStore.query(PresentedCarRecordSnapshot, 
            r => r.presentedcarsnapshotID.eq(presentedCarSnapshotId),
            { sort: r => r.recordDate(order === "ASC" ? SortDirection.ASCENDING : SortDirection.DESCENDING) 
        });

        console.log(records);
        console.log(records.length);
        this.setState({ loading: false, recordNum: records.length });
        } catch (err) {
        console.error(err);
        }
    }

    componentDidMount() {
        const listener = Hub.listen('datastore', async hubData => {
          const { event, data } = hubData.payload;
          if (event === 'ready') {
            this.fetchRecords();
          }
        });
      }
    
      componentDidUpdate(prevProps) {
        if (this.props.order !== prevProps.order) {
          this.fetchRecords();
        }
      }
  
    render() {
        const { order, setOrder, presentedCarSnapshotId } = this.props;
        const {loading, recordNum} = this.state;
        console.log(recordNum)
    //   const recordNum = this.props.presentedCarSnapshotId.map().length;
        function MyComponent(props) {
            const myNumber = props.myNumber;
        
            if (myNumber > 1) {
                return <p className="recNum">Papertrail has {myNumber} records for this vehicle.</p>;
            } else {
                return <p className="recNum">Papertrail has {myNumber} record for this vehicle.</p>;
            }
        }
    
        if (loading) {
            return <div> </div>
        } else {
            return (
                <div>
                <h1 className="divider">
                    <b>Records</b>
                </h1>
                <MyComponent myNumber={recordNum} />
                <div className="filterHolder">
                    <div className="blankSpace">
                    </div>
                    <div className="filter">
                        <select value={order} onChange={this.handleSelect}>
                            <option value="DESC">Data: New to Old</option>
                            <option value="ASC">Data: Old to New</option>
                        </select>
                    </div>
                </div>
                </div>
            );
        }
    }
  }
  
  export default PageDivider;

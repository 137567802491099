import React, { Component } from "react";
import "./CarDescription.css";
import { Storage } from 'aws-amplify';
import placeholderIMG from '../resources/papertrailIcon.png';

class CarDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyToURL: '',
      loading: true
    };
  }

  async fetchImage() {
    const { picKeyList } = this.props;
    try {
      // Handle case where car contains multiple images (only display first one)
      const firstImage = picKeyList.split(',')[0];
      console.log('Image URL:', firstImage);
      if (firstImage === '' || picKeyList === null) {
        console.log('No image found');
        this.setState({ keyToURL: placeholderIMG, loading: false });
      } else {
        console.log('ewwww', firstImage);
        const image = await Storage.get(firstImage, { expires: 3600, level: 'public', download: false, contentType: 'image/jpeg' });
        
      
        this.setState({ keyToURL: image, loading: false });
      }
      // this.setState({ keyToURL: image, loading: false });
    } catch (error) {
      console.log('Error fetching image: ', error);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.fetchImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.picKeyList !== prevProps.picKeyList) {
      this.fetchImage();
    }
  }

  render() {
    const { keyToURL, loading } = this.state;
    if (loading) {
      return <div>Loading...</div>
    } else {
      return (
        <div className="imgContainer">
          <img className='vehicleImg' src={keyToURL} alt="Car" />
        </div>
      );
    }
    
  }
}

export default CarDescription;

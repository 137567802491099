import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import placeholderImage from '../components/resources/pt_logo.png'



// This file works as a backend for the Portal component.

class PortalBackend {

    constructor(userID) {
        this.userID = userID;
        this.carSnapshots = [];
    }

    /// METHODS /// 

    // Initialize the backend and its fields
    async initialize() {
        this.carSnapshots = await this.getPresentedCarSnapshots(this.userID);
        // for (var i in this.carSnapshots) {
        //     console.log("Car snapshot: ", this.carSnapshots[i].id)
        // }
    }

    // Helper method for fetching a list of PresentedCarSnapshot
    async getPresentedCarSnapshots(userID, nextToken = null) {
        let isLoggedIn = await this.isLoggedIn();
        // console.log("Is logged in: ", isLoggedIn)
        let carSnapshots = [];

        try {
            const variables = {
                limit: 100,
                filter: {
                    owner: { eq: userID },
                    // _deleted: { ne: true}
                },
                nextToken: nextToken
            };

            const result = await API.graphql({
                ...graphqlOperation(queries.listPresentedCarSnapshots, variables),
                authMode: isLoggedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            });

            const items = result.data.listPresentedCarSnapshots.items.filter(item => !item._deleted);  // Filter out deleted items

            carSnapshots.push(...items);  // Use spread operator to push individual items to the list

            // If a nextToken is present, make a recursive call
            if (result.data.listPresentedCarSnapshots.nextToken) {
                const moreCarSnapshots = await this.getPresentedCarSnapshots(userID, result.data.listPresentedCarSnapshots.nextToken);
                carSnapshots = carSnapshots.concat(moreCarSnapshots);  // Concatenate results of the recursive call to the current list
            }

        } catch (error) {
            console.error("Error fetching PresentedCarSnapshots:", error);
        }

        return carSnapshots;
    }


    async isLoggedIn() {
        // Another way to get if its a guest or not
        //return await Auth.Credentials.getCredSource() === "guest"
        try {
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
      }

}


export default PortalBackend;
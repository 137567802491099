import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Presentation from '../presentation/Presentation.jsx';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

const PresentationEditor = ({ user, signOut }) => {
  const { presentedCarSnapshotId } = useParams();
  const [snapshotBelongsToUser, setSnapshotBelongsToUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchCarSnapshots = async (nextToken = null) => {

      let carSnapshots = [];

      try {
        const variables = {
          limit: 100,
          filter: {
            owner: { eq: user.username }
          },
          nextToken: nextToken
        };

        const result = await API.graphql({
          ...graphqlOperation(queries.listPresentedCarSnapshots, variables),
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const items = result.data.listPresentedCarSnapshots.items;

        carSnapshots.push(...items);  // Use spread operator to push individual items to the list

        // If a nextToken is present, make a recursive call
        if (result.data.listPresentedCarSnapshots.nextToken) {
          const moreCarSnapshots = await fetchCarSnapshots(result.data.listPresentedCarSnapshots.nextToken);
          carSnapshots = carSnapshots.concat(moreCarSnapshots);  // Concatenate results of the recursive call to the current list
        }

      } catch (error) {
        console.error("Error fetching PresentedCarSnapshots count:", error);
      }

      return carSnapshots;
    }



    const checkOwnership = async () => {
      if (user && user.username) {
        try {
          const snapshots = await fetchCarSnapshots();
          const belongsToUser = snapshots.some(snapshot => snapshot.id === presentedCarSnapshotId);
          setSnapshotBelongsToUser(belongsToUser);
        } catch (error) {
          console.error("Error checking snapshot ownership:", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.error("User is not logged in");
        setLoading(false);
      }
    };

    checkOwnership();


  }, [user, presentedCarSnapshotId]);


  if (loading) return <div>Loading...</div>;
  if (!snapshotBelongsToUser) return <div>This snapshot does not belong to the current user</div>;

  
  return (
    <Presentation isEdit={true} />
  )
}

export default withAuthenticator(PresentationEditor)
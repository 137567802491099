// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PushNotificationService = {
  "APNS": "APNS",
  "FCM": "FCM"
};

const { Transfer, CarTodoItem, PresentedCarRecordSnapshot, PresentedCarSnapshot, UserData, NotificationLog, DeviceToken, CarRecords, Car, Verification, EventLog, SocialPost, Like, SecureUserData } = initSchema(schema);

export {
  Transfer,
  CarTodoItem,
  PresentedCarRecordSnapshot,
  PresentedCarSnapshot,
  UserData,
  NotificationLog,
  DeviceToken,
  CarRecords,
  Car,
  Verification,
  EventLog,
  SocialPost,
  Like,
  SecureUserData,
  PushNotificationService
};
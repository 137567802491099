import React from "react";
import companyIcon from './resources/papertrailIcon.png';
import ptLogo from './resources/pt_logo.png';
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <nav className="navigation">
            <div className="companyLogo">
                <img src={companyIcon} alt="Company Icon" />
            </div>
            <div className="navigation-menu">
                <ul>
                    <li>
                        <Link to="https://www.papertrailauto.com" className="intro-button">
                            <img src={ptLogo} alt="Papertrail Logo" className="pt-logo" />
                            What's Papertrail?
                        </Link>
                    </li>

                    <li>
                        <Link to="https://www.papertrailauto.com/download" className="redirect-button">Download Papertrail</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Header;


import React, { useState } from 'react';
import ImageSlider from './ImageSlider';
import './ImageCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import placeholderImage from '../../resources/pt_logo.png';

const ImageCarousel = ({ images, aspectRatio, maxWidth }) => {
  const slideCount = images.length;
  const slideWidth = 100 / slideCount;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [fullImageSrc, setFullImageSrc] = useState(null);

  const [widthRatio, heightRatio] = aspectRatio.split(':').map(Number);
  const aspectPercentage = (heightRatio / widthRatio) * 100;

  const handlePrevClick = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setFullImageSrc(images[newIndex].url);
  };

  const handleNextClick = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setFullImageSrc(images[newIndex].url);
  };

  const handleImageClick = (imageSrc) => {
    setFullImageSrc(imageSrc.url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setFullImageSrc(null);
  };

  const onSliderImageClick = (index) => {
    setCurrentIndex(index);
    setFullImageSrc(images[index].url);
  };

  return (
    <div
      className="carousel-wrapper"
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: maxWidth,
        margin: '0 auto',
        overflow: 'hidden'
      }}
    >      {images.length === 1 && images[0].url === placeholderImage ? (
      <div className="placeholder-car-image-container">
        <img className="placeholder-car-image" src={placeholderImage} alt="Placeholder for car" />
      </div>
    ) : (
      <>
        <div className="carousel-container">
          <div className="carousel-slide" style={{ width: `${slideCount * 100}%`, transform: `translateX(-${currentIndex * slideWidth}%)` }}>
            {images.map((image, index) => (
              <div key={index} className="carousel-image-container" style={{ '--aspect-percentage': `${aspectPercentage}%` }} onClick={() => handleImageClick(image)}>
                <img className="carousel-image" src={image.url} alt={`Carousel ${index}`} />
              </div>
            ))}
          </div>

          <FontAwesomeIcon icon={faChevronLeft} className="carousel-prev-btn" onClick={handlePrevClick} />
          <FontAwesomeIcon icon={faChevronRight} className="carousel-next-btn" onClick={handleNextClick} />
        </div>

        <ImageSlider images={images} currentIndex={currentIndex} onClick={onSliderImageClick} width={500} centered={false} />

        {modalOpen && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <FontAwesomeIcon icon={faTimes} className="modal-close-btn" onClick={handleCloseModal} />
              <div className="modal-image-wrapper">
                <img src={fullImageSrc} className="modal-image" alt="Full Size Car" />
                <FontAwesomeIcon icon={faChevronLeft} className="modal-arrow modal-prev-arrow" onClick={handlePrevClick} />
                <FontAwesomeIcon icon={faChevronRight} className="modal-arrow modal-next-arrow" onClick={handleNextClick} />
              </div>
              <ImageSlider images={images} currentIndex={currentIndex} onClick={onSliderImageClick} width={800} centered={true} />
            </div>
          </div>
        )}
      </>
    )}
    </div>
  );
};

export default ImageCarousel;
import "./Transfer.css";
import CarDescription from "./CarDescription.js";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Amplify, API, Hub } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { Transfer } from "../../models";
import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import { useLocation } from 'react-router-dom';

function AcceptPage() {
  let navigate = useNavigate();
  let { transferId } = useParams();
  const [transfer, setTransfer] = React.useState(null);
  const location = useLocation();

  function fetchDataAndRenderPage(){
    DataStore.start().then(() => {
      const removeListener = Hub.listen("datastore", async (capsule) => {
            const {
              payload: { event, data },
            } = capsule;

            console.log("DataStore event", event, data);

            if (event === "ready") {
              const transferInfo = await DataStore.query(Transfer, transferId);
              // "refresh" the transfer info and the page
              // in any funtional component, you can use the state to trigger a re-render
              setTransfer(transferInfo);
              console.log(transferInfo);
              if (transferInfo.isDone) {
                navigate("/transfer-fail");
              }
            }
          }
      );
    });
  }

  const handleLoginClick = () => {
    // Redircet to transfer key page with login authentication
    // navigate(`/transfer-key/${transferId}`);
    navigate(`/transfer-login/${transferId}`);
  };

  const queryTransfer = async () => {
    const transferInfo = await DataStore.query(Transfer, transferId);
    setTransfer(transferInfo);
    console.log(transferInfo);
    if (transferInfo.isDone) {
      navigate("/transfer-fail");
    }
  }

  React.useEffect(() => {
    // Create listener that will stop observing the model once the sync process is done
    fetchDataAndRenderPage();
    queryTransfer();
  }, [location.pathname]);
  
  const propsData = {
    carDescription: {
      carMakeAndModel: transfer ? transfer.carMake + " " + transfer.carModel : "",
      year: transfer ? transfer.carYear : "",
      vin: transfer ? transfer.carVIN : "",
      picKeyList: transfer ? transfer.picKeyList ? transfer.picKeyList.split(",")[0] : "" : "",
    },
  };

  console.log(propsData.carDescription); 
  return (
    <div id="body">
      <div id="transfer-body">
        <div className="intro">
          <p id="greeting-message"><span style={{fontWeight:"bold"}}>&#x1F44B; Welcome to Papertrail!</span></p>
          <p> The full history of your new <span style={{fontWeight:"bold"}}> {propsData.carDescription.year} {propsData.carDescription.carMakeAndModel}</span> will be transferred to you.</p>
        </div>
        <CarDescription {...propsData.carDescription} />
        <div className="disclaimer">
          {/*<p><span style={{fontWeight:"bold"}}>&#x1F914; Wait, what's going on?</span></p>*/}
          {/*<p>&#127950; Papertrail is a <span style={{fontWeight:"bold"}}>FREE online platform</span> on which enthusiasts can <span style={{fontWeight:"bold"}}>add, manage, verify, present, and transfer</span> all the records of their vehicles/items.</p>*/}
          {/*<p>&#x1F4F1; With our app freely available on Android and iOS, we help you <span style={{fontWeight:"bold"}}>document your journey</span> and <span style={{fontWeight:"bold"}}>keep your investment safe!</span></p>*/}
          {/*<p>&#x1F3C1; <span style={{fontWeight:"bold"}}>Ready to roll?</span> Accept the transfer, and let's keep the history of our passion alive!</p>*/}

          <p>What is <span style={{fontWeight:"bold"}}>Papertrail?</span></p>

          <p>Papertrail is a <span style={{fontWeight:"bold"}}>comprehensive online platform</span> that allows enthusiasts and collectors to <span style={{fontWeight:"bold"}}>add, manage, verify, present, and transfer records for their vehicles or items.</span></p>

          <p>We are available on both <span style={{fontWeight:"bold"}}>Android</span> and <span style={{fontWeight:"bold"}}>iOS.</span></p>

          <p>To proceed, please accept the transfer, and together, we shall preserve the history of our passion!</p>

        </div>
        <div className="acceptBotton">
          <div>
            {/*<h1>Login or sign up to accept record tranfer</h1>*/}
            <button onClick={handleLoginClick}>Accept Tranfer Now!</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptPage;

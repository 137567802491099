import Header from './components/Header.js';
import Slider from './components/slider/Slider.js';
import AcceptPage from './components/transfer/AcceptPage.jsx';
import LoginPage from './components/transfer/LoginPage.js';
import TransferPage from './components/transfer/TransferPage.jsx';
import TransferSuccess from './components/transfer/TransferSuccess.js';
import TransferFailure from './components/transfer/TransferFailure.js';
import Presentation from './components/presentation/Presentation.jsx';
import PortalLoginPage from './components/portal/PortalMain.jsx';
import './App.css';
import React from "react";
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Redirect from './components/redirect.js';
import NewLogin from './components/transfer/NewLogin.js';
import PresentationEditor from './components/portal/PresentationEditor.jsx'

Amplify.configure(
  {
    ...awsconfig,
    DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    },
  }
)
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
          <Routes>
            {/* Render the appropriate component for the current route */}
            {/* <Route exact path="/" element={<Slider />} /> */}
            {/* <Route path="/" element={<Redirect />} /> */}
            <Route path="/" element={<PortalLoginPage/>} />
            <Route path="/edit/:presentedCarSnapshotId" element={<PresentationEditor/>} />

            {/* Presentation Page */}
            <Route path="/presentation/:presentedCarSnapshotId" element={<Presentation />} />
            <Route path="/presents/:presentedCarSnapshotId" element={<Presentation />} />

            {/* Transfer Page
            AcceptPage --> TransferPage --> TransferSuccess/TransferFailure */}
            {/* Transfer Page
            AcceptPage --> NewLogin --> TransferPage --> TransferSuccess/TransferFailure */}
            <Route path="/transfer/:transferId" element={<AcceptPage />} />
            <Route path="/transfer-login/:transferId" element={<NewLogin />} />
            <Route path="/transfer-key/:transferId" element={<TransferPage />} />
            {/* where you can log out and log in with the new user */}
            <Route path="/login/:transferId" element={<LoginPage />} />
            <Route path="/transfer-success" element={<TransferSuccess />} />
            <Route path="/transfer-fail" element={<TransferFailure />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

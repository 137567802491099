/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTransfer = /* GraphQL */ `
  query GetTransfer($id: ID!) {
    getTransfer(id: $id) {
      id
      carID
      recipientEmail
      passphrase
      carMake
      carModel
      carYear
      carVin
      picKeyList
      isConfirmed
      isDone
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransfers = /* GraphQL */ `
  query ListTransfers(
    $filter: ModelTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        carID
        recipientEmail
        passphrase
        carMake
        carModel
        carYear
        carVin
        picKeyList
        isConfirmed
        isDone
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransfers = /* GraphQL */ `
  query SyncTransfers(
    $filter: ModelTransferFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransfers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        carID
        recipientEmail
        passphrase
        carMake
        carModel
        carYear
        carVin
        picKeyList
        isConfirmed
        isDone
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCarTodoItem = /* GraphQL */ `
  query GetCarTodoItem($id: ID!) {
    getCarTodoItem(id: $id) {
      id
      todoItem
      isDone
      description
      carID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listCarTodoItems = /* GraphQL */ `
  query ListCarTodoItems(
    $filter: ModelCarTodoItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarTodoItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        todoItem
        isDone
        description
        carID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarTodoItems = /* GraphQL */ `
  query SyncCarTodoItems(
    $filter: ModelCarTodoItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarTodoItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        todoItem
        isDone
        description
        carID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPresentedCarRecordSnapshot = /* GraphQL */ `
  query GetPresentedCarRecordSnapshot($id: ID!) {
    getPresentedCarRecordSnapshot(id: $id) {
      id
      title
      dateUnsure
      description
      whoDidTheJob
      recordDate
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      owner
      noTime
      presentedcarsnapshotID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPresentedCarRecordSnapshots = /* GraphQL */ `
  query ListPresentedCarRecordSnapshots(
    $filter: ModelPresentedCarRecordSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentedCarRecordSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        dateUnsure
        description
        whoDidTheJob
        recordDate
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        owner
        noTime
        presentedcarsnapshotID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPresentedCarRecordSnapshots = /* GraphQL */ `
  query SyncPresentedCarRecordSnapshots(
    $filter: ModelPresentedCarRecordSnapshotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPresentedCarRecordSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        dateUnsure
        description
        whoDidTheJob
        recordDate
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        owner
        noTime
        presentedcarsnapshotID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPresentedCarSnapshot = /* GraphQL */ `
  query GetPresentedCarSnapshot($id: ID!) {
    getPresentedCarSnapshot(id: $id) {
      id
      year
      make
      carModel
      carVIN
      acquisitionDate
      acquisitionMileage
      odometerInMiles
      currentMileage
      color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      showMileage
      showInvestment
      showDoneBy
      showOverallDescription
      primaryRecordListMode
      overallDescription
      totalInvestment
      lastRecordMileage
      totalImagesCount
      totalRecordsCount
      carSnapshotsHaveRecordsSnapshots {
        nextToken
        startedAt
      }
      carID
      timeToDie
      highlightedRecords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPresentedCarSnapshots = /* GraphQL */ `
  query ListPresentedCarSnapshots(
    $filter: ModelPresentedCarSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentedCarSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        make
        carModel
        carVIN
        acquisitionDate
        acquisitionMileage
        odometerInMiles
        currentMileage
        color
        picKeyList
        transmissionType
        coverPicKey
        engine
        owner
        showMileage
        showInvestment
        showDoneBy
        showOverallDescription
        primaryRecordListMode
        overallDescription
        totalInvestment
        lastRecordMileage
        totalImagesCount
        totalRecordsCount
        carID
        timeToDie
        highlightedRecords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPresentedCarSnapshots = /* GraphQL */ `
  query SyncPresentedCarSnapshots(
    $filter: ModelPresentedCarSnapshotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPresentedCarSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        year
        make
        carModel
        carVIN
        acquisitionDate
        acquisitionMileage
        odometerInMiles
        currentMileage
        color
        picKeyList
        transmissionType
        coverPicKey
        engine
        owner
        showMileage
        showInvestment
        showDoneBy
        showOverallDescription
        primaryRecordListMode
        overallDescription
        totalInvestment
        lastRecordMileage
        totalImagesCount
        totalRecordsCount
        carID
        timeToDie
        highlightedRecords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      agreedToPolicy
      userEmail
      points
      premiumStatus
      userName
      owner
      imageCompressionRate
      likes {
        nextToken
        startedAt
      }
      hiddenPosts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserData = /* GraphQL */ `
  query SyncUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCarRecords = /* GraphQL */ `
  query GetCarRecords($id: ID!) {
    getCarRecords(id: $id) {
      id
      noTime
      isStory
      dateUnsure
      Title
      Description
      whoDidTheJob
      hoursSpent
      carID
      CarRecordsHasVerification {
        nextToken
        startedAt
      }
      recordDate
      recordDateTime
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      isArchived
      owner
      socialPost {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carRecordsSocialPostId
    }
  }
`;
export const listCarRecords = /* GraphQL */ `
  query ListCarRecords(
    $filter: ModelCarRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarRecords = /* GraphQL */ `
  query SyncCarRecords(
    $filter: ModelCarRecordsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      nextToken
      startedAt
    }
  }
`;
export const getCar = /* GraphQL */ `
  query GetCar($id: ID!) {
    getCar(id: $id) {
      id
      Year
      Make
      CarModel
      carVIN
      Notes
      Metrics
      CarHasRecord {
        nextToken
        startedAt
      }
      acquisitionDate
      acquisitionDateTime
      acquisitionMileage
      odometerInMiles
      currentMileage
      Color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      PresentedCarSnapshots {
        nextToken
        startedAt
      }
      CarTodoItems {
        nextToken
        startedAt
      }
      isArchived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCars = /* GraphQL */ `
  query ListCars(
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Year
        Make
        CarModel
        carVIN
        Notes
        Metrics
        acquisitionDate
        acquisitionDateTime
        acquisitionMileage
        odometerInMiles
        currentMileage
        Color
        picKeyList
        transmissionType
        coverPicKey
        engine
        owner
        isArchived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCars = /* GraphQL */ `
  query SyncCars(
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Year
        Make
        CarModel
        carVIN
        Notes
        Metrics
        acquisitionDate
        acquisitionDateTime
        acquisitionMileage
        odometerInMiles
        currentMileage
        Color
        picKeyList
        transmissionType
        coverPicKey
        engine
        owner
        isArchived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVerification = /* GraphQL */ `
  query GetVerification($id: ID!) {
    getVerification(id: $id) {
      id
      isVerified
      isVerifiable
      verifiedBy
      verifiedAt
      owner
      notes
      carRecordsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVerifications = /* GraphQL */ `
  query ListVerifications(
    $filter: ModelVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isVerified
        isVerifiable
        verifiedBy
        verifiedAt
        owner
        notes
        carRecordsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVerifications = /* GraphQL */ `
  query SyncVerifications(
    $filter: ModelVerificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isVerified
        isVerifiable
        verifiedBy
        verifiedAt
        owner
        notes
        carRecordsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventLog = /* GraphQL */ `
  query GetEventLog($id: ID!) {
    getEventLog(id: $id) {
      id
      actions
      createdAtTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEventLogs = /* GraphQL */ `
  query ListEventLogs(
    $filter: ModelEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actions
        createdAtTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventLogs = /* GraphQL */ `
  query SyncEventLogs(
    $filter: ModelEventLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actions
        createdAtTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSocialPost = /* GraphQL */ `
  query GetSocialPost($id: ID!) {
    getSocialPost(id: $id) {
      typ
      id
      title
      description
      owner
      picKeyList
      postedAt
      carRecords {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      likes {
        nextToken
        startedAt
      }
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      socialPostCarRecordsId
    }
  }
`;
export const listSocialPosts = /* GraphQL */ `
  query ListSocialPosts(
    $filter: ModelSocialPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSocialPosts = /* GraphQL */ `
  query SyncSocialPosts(
    $filter: ModelSocialPostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSocialPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      nextToken
      startedAt
    }
  }
`;
export const socialPostByDate = /* GraphQL */ `
  query SocialPostByDate(
    $typ: String!
    $postedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSocialPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    socialPostByDate(
      typ: $typ
      postedAt: $postedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      user {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      post {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      isDeleted
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDataLikesId
      socialPostLikesId
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isDeleted
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userDataLikesId
        socialPostLikesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLikes = /* GraphQL */ `
  query SyncLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isDeleted
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userDataLikesId
        socialPostLikesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getSecureUserData = /* GraphQL */ `
  query GetSecureUserData($id: ID!) {
    getSecureUserData(id: $id) {
      owner
      id
      credit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSecureUserData = /* GraphQL */ `
  query ListSecureUserData(
    $filter: ModelSecureUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecureUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        id
        credit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSecureUserData = /* GraphQL */ `
  query SyncSecureUserData(
    $filter: ModelSecureUserDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSecureUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        owner
        id
        credit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

import "./Records.css";
import 'react-awesome-slider/dist/styles.css';
import React, { Component } from "react";
import "../../slider/Slider.css";
import { DataStore } from 'aws-amplify';
import { PresentedCarRecordSnapshot } from "../../../models";
import { SortDirection } from "@aws-amplify/datastore";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from '../../slider/SliderPicKeyList.jsx';
import RecordDetail from './recordDetail.jsx';
import Carousel from 'react-material-ui-carousel';
import { Hub } from "@aws-amplify/core";

class Records extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      records: [],
    };
  }

  componentDidMount() {
    const listener = Hub.listen('datastore', async hubData => {
      const { event, data } = hubData.payload;
      if (event === 'ready') {
        this.fetchRecords();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      this.fetchRecords();
    }
  }

  async fetchRecords() {
    const { presentedCarSnapshotId, order } = this.props;
    try {
      const records = await DataStore.query(PresentedCarRecordSnapshot,
          r => r.presentedcarsnapshotID.eq(presentedCarSnapshotId)
      );

      // Custom sorting function to sort by date
      const sortByDate = (a, b) => {
        const dateA = new Date(a.recordDate);
        const dateB = new Date(b.recordDate);
        if (order === "ASC") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      };

      // Sort records using custom sorting function
      const sortedRecords = records.sort(sortByDate);

      this.setState({ records: sortedRecords, loading: false });
    } catch (err) {
      console.error(err);
    }
  }

  displayRecord(record) {
    const regex = /(\w+)(:.*?)(?=\s+\w+:|$)/g
    const str = record.description
    const newstr = Array.from(str.matchAll(regex), m => `<b>${m[1]}</b>${m[2]}`);

      return (
        <div className='individualRecords' key={record.id}>
          <div>
            <p>
              <h2>{record.title} </h2>
              <b>Date:</b> {record.recordDate}
              {record.dateUnsure && <span> (approximate)</span>}
            </p>
          </div>

          <div className="detailContainer">
            <div className="recordsPhotos">
              <Slider picKeyList={record.picKeyList}/>
            </div>

            <div className='recordsInfo'>
              <p>
                <b>Mileage:</b> {record.CurrMileage}
              </p>
              <p>
                <b>Price:</b> {record.Cost}
              </p>
              <p>
                <b>Done by:</b> {record.whoDidTheJob}
              </p>
              <p>
                <b>Description:</b>
              </p>
              <p style={{whiteSpace: "pre-line"}}>
                {record.description}
              </p>

            </div>
          </div>


        </div>
      );
  }

  render() {
    const { loading, records } = this.state;

    if (loading) {
      return <div>
      </div>
    } else {
      return (
        <div className='vehicleRecords'>
          {records.map(record => this.displayRecord(record))}
        </div>
      );
    }
  }
}

export default Records;
import React, { useEffect, useState, useRef } from 'react';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import "./RecordSection.css";
import "./DescriptionSection.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { faRoad, faTimes } from '@fortawesome/free-solid-svg-icons';
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import PhotoCarouselForRecords from './ImageCarousel/PhotoCarouselForRecords.js';
import ExpandableParagraph from './ExpandableParagraph';

function IndividualRecordsComponent({ presentedCarSnapshotId, records, mileageUnit, backend, isEdit}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recordDataUpdated, setRecordDataUpdated] = useState(false);

    const [currentlyEditingRecord, setCurrentlyEditingRecord] = useState(null);

    
    const handleOpenModal = (event) => {
        const targetRecord = records.find(rec => rec.id === event.currentTarget.id);
        setCurrentlyEditingRecord(targetRecord);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    console.log("+++++++++records", records);
    console.log(records);

    // useEffect(() => {
    // if (backend) {
    //     // console.log("+++++++++recordDataUpdated")
    //     // if (sortMode === "time-line") setRecords(backend.datedRecordsDescending);
    //     // else if (sortMode === "other-histories") setRecords(backend.undatedRecords);
        
    //     /*
    //     TODO:
    //     When records are edited, the current implementation is to refresh the webpage, which is not an elegant solution.
    //     The forced refresh is to circumvent a few issues with the current iteration of the webapp:
        
    //     1. When the mileage/investment of a record is updated, there are cases where the total investment
    //     and last recorded mileage are updated as well. The two components are not aware of each others' changes,
    //     so the code should be refactored so that the backend and/or a state should be passed from the
    //     parent component, Presentation.

    //     2. When a record has an updated date/mileage, it's placement within the list of records change 
    //     as they are ordered by the dates/mileage. For reasons unknown, the text description of the records 
    //     (title, doneBy, investment, date/mileage, description) are relocated to appropriate place within the list,
    //     but the image carousel does not get relocated. The result is two records with mismatched image carousel.
    //     This happens with setState() / useEffect. The forced refresh circumvents this issue.

    //     */
    //         window.location.reload();
    //     }
    
    // }, [recordDataUpdated]);
    
    // Create refs dynamically based on records.
    let paragraphRef = records.reduce((acc, record) => {
        acc[record.title] = React.createRef();
        return acc;
    }, {});

    return (
        <div className="records">
            {records.map((record, index) => (
                <div key={index} className="individual-record">
                    <div className='individual-record-carousel'>
                        <PhotoCarouselForRecords presentedCarSnapshotId={presentedCarSnapshotId} backend={backend} recordImageKeys={record.images} />
                    </div>
                    <div className="record-details">
                        {record.isHighlighted && 
                            <div className='record-bookmark-icon'>
                                <FontAwesomeIcon icon={faBookmark} style={{color: "#5da252",}} size="lg" />
                            </div>
                        }
                        <div className="record-title-doneby">
                            <h3>{record.title}</h3>
                            {backend && backend.showDoneByForEachRecord && record.doneBy !== "N/A" && <p>by {record.doneBy}</p>}
                            
                        </div>
                        <div className="individual-record-summary-group">
                             
                            {backend && backend.showMileage && record.date !== "NaN-NaN-undefined" && record.date !== "N/A" &&
                             <div className='record-summary'>
                                 <div className="record-icon-circle-border">
                                     <FontAwesomeIcon icon={faRoad} size="lg" />
                                 </div>
                                 <div className="record-summary-text">
                                     <span className='record-grey-text'><h4>Recorded at: </h4></span>
                                     <div className="record-detail-container">
                                         {record.mileage !== "N/A" && (
                                            <>
                                                <h5 className="record-amount">{record.mileage}</h5>
                                                <p>{mileageUnit}/ {record.date}</p>
                                            </>
                                            )}
                                            {record.mileage === "N/A" &&  record.date !== "N/A" && record.date !== "NaN-NaN-undefined" && <h5>{record.date}</h5>}
                                     </div>
                                 </div> 
                             </div>
                            }

                            {backend && backend.showMileage && record.date !== "N/A" && record.date !== "NaN-NaN-undefined" && record.investment !== "N/A" && backend && backend.showTotalInvestment &&
                                <>
                                <div className="divider"></div>
                                </>
                            }

                            {record.investment !== "N/A" && backend && backend.showTotalInvestment && 
                                <>
                                <div className='record-summary'>
                                    <div className="record-icon-circle-border">
                                        <FontAwesomeIcon icon={faSackDollar} size="lg" />
                                    </div>
                                    <div className="record-summary-text">
                                        <span className='record-grey-text'><h4>Recorded Investment: </h4></span>
                                        <div className="record-detail-container">
                                            <p className="unit-sign">$</p>
                                            <h5 className="record-amount">{record.investment}</h5>
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                             
                        </div>
                        <div className="expandable-text-description-group">
                            <ExpandableParagraph textContent={record.description} />
                        </div>
                        {isEdit &&
                        <div className="edit-record-info-btn-container">
                            <button id={record.id} className="edit-record-info-btn" onClick={handleOpenModal}>Edit Information</button>
                        </div>
                        }
                    </div>
                    
               
                </div>
                
            ))}
                {isEdit && <EditRecordInformationModal
                    backend={backend}
                    record={currentlyEditingRecord}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onDataUpdate={() => setRecordDataUpdated(!recordDataUpdated)} 
                />}
        </div>
    );
}



function EditRecordInformationModal({ backend, record, isOpen, onClose, onDataUpdate}) {
    const [formData, setFormData] = useState({
      title: "",
      doneBy: "",
      mileage: 0.0,
      date: "",
      investment: 0.0,
      description: "",
      isHighlighted: false,
    });
  
  
    useEffect(() => {
      if (isOpen && backend && record) {
        setFormData({
            title: record.title || '',
            doneBy: record.doneBy || '',
            mileage: record.mileage || 0,
            date: formatDatePreEdit(record.date) || '',
            investment: record.investment || 0,
            description: record.description || '',
            isHighlighted: record.isHighlighted || false,
        });
      }
    }, [isOpen, backend, record]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      let formattedValue = value;

      if (name === "date") {
        formattedValue = formatDatePostEdit(value);
    }
    setFormData((prev) => ({ ...prev, [name]: formattedValue }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({ ...prev, [name]: checked }));
    };
  
    const handleSubmit = async (recordID) => {

        console.log("formData", formData);
      await backend.updateRecordSnapshotInformation(formData, recordID);
      onClose(); // Close modal after submission
      if (onDataUpdate) onDataUpdate(); // Check and call the function
      window.location.reload();
  };

    const formatDatePreEdit = (dateString) => {
        if (dateString === "N/A") {
            return dateString;
        }
        let [month, day, year] = dateString.split('-');        
        if (Number(month) < 10) {
            month = "0" + month;
        }

        if (Number(day) < 10) {
            day = "0" + day;
        }
        return `${year}-${month}-${day}`;
    };


    const formatDatePostEdit = (dateString) => {

        if (dateString === "" || dateString === null || dateString === undefined) {
            return "N/A"
        }

        let [year, month, day] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };

  
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <FontAwesomeIcon icon={faTimes} className="modal-close-btn" onClick={onClose} />
          <div className="modal-form">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title || ''}
              onChange={handleChange}
              placeholder="Title"
            />

            <label htmlFor="highlighted">
                <input
                type="checkbox"
                id="highlighted"
                name="isHighlighted"
                checked={formData.isHighlighted}
                onChange={handleCheckboxChange}
                />
                Highlighted
            </label>
  
            <label htmlFor="doneBy">Done By</label>
            <input
              type="text"
              id="doneBy"
              name="doneBy"
              value={formData.doneBy || ''}
              onChange={handleChange}
              placeholder="Done By"
            />
  
            <label htmlFor="mileage">Mileage ({backend.mileageUnit})</label>
            <input
              type="number"
              id="mileage"
              name="mileage"
              value={formData.mileage || ''}
              onChange={handleChange}
              placeholder="0.0"
            />
  
            <label htmlFor="date">Date</label>
            <input
            type="date"
              id="date"
              name="date"
              value={formData.date || ''}
              onChange={handleChange}
              placeholder="Date"
            />


            <label htmlFor="investment">Investment</label>
            <input
            type="number"
            id="investment"
            name="investment"
            value={formData.investment || ''}
            onChange={handleChange}
            placeholder="0.0"
            />


            <label htmlFor="description">Description</label>
            <textarea
                id="description"
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
                placeholder="Description"
            />
       
  
          </div>
          <button className="save-changes-btn" onClick={() => handleSubmit(record.id)}>Save Changes</button>
  
        </div>
      </div>
    );
  }



export default IndividualRecordsComponent;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTransfer = /* GraphQL */ `
  mutation CreateTransfer(
    $input: CreateTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    createTransfer(input: $input, condition: $condition) {
      id
      carID
      recipientEmail
      passphrase
      carMake
      carModel
      carYear
      carVin
      picKeyList
      isConfirmed
      isDone
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTransfer = /* GraphQL */ `
  mutation UpdateTransfer(
    $input: UpdateTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    updateTransfer(input: $input, condition: $condition) {
      id
      carID
      recipientEmail
      passphrase
      carMake
      carModel
      carYear
      carVin
      picKeyList
      isConfirmed
      isDone
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTransfer = /* GraphQL */ `
  mutation DeleteTransfer(
    $input: DeleteTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    deleteTransfer(input: $input, condition: $condition) {
      id
      carID
      recipientEmail
      passphrase
      carMake
      carModel
      carYear
      carVin
      picKeyList
      isConfirmed
      isDone
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCarTodoItem = /* GraphQL */ `
  mutation CreateCarTodoItem(
    $input: CreateCarTodoItemInput!
    $condition: ModelCarTodoItemConditionInput
  ) {
    createCarTodoItem(input: $input, condition: $condition) {
      id
      todoItem
      isDone
      description
      carID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateCarTodoItem = /* GraphQL */ `
  mutation UpdateCarTodoItem(
    $input: UpdateCarTodoItemInput!
    $condition: ModelCarTodoItemConditionInput
  ) {
    updateCarTodoItem(input: $input, condition: $condition) {
      id
      todoItem
      isDone
      description
      carID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteCarTodoItem = /* GraphQL */ `
  mutation DeleteCarTodoItem(
    $input: DeleteCarTodoItemInput!
    $condition: ModelCarTodoItemConditionInput
  ) {
    deleteCarTodoItem(input: $input, condition: $condition) {
      id
      todoItem
      isDone
      description
      carID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createPresentedCarRecordSnapshot = /* GraphQL */ `
  mutation CreatePresentedCarRecordSnapshot(
    $input: CreatePresentedCarRecordSnapshotInput!
    $condition: ModelPresentedCarRecordSnapshotConditionInput
  ) {
    createPresentedCarRecordSnapshot(input: $input, condition: $condition) {
      id
      title
      dateUnsure
      description
      whoDidTheJob
      recordDate
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      owner
      noTime
      presentedcarsnapshotID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePresentedCarRecordSnapshot = /* GraphQL */ `
  mutation UpdatePresentedCarRecordSnapshot(
    $input: UpdatePresentedCarRecordSnapshotInput!
    $condition: ModelPresentedCarRecordSnapshotConditionInput
  ) {
    updatePresentedCarRecordSnapshot(input: $input, condition: $condition) {
      id
      title
      dateUnsure
      description
      whoDidTheJob
      recordDate
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      owner
      noTime
      presentedcarsnapshotID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePresentedCarRecordSnapshot = /* GraphQL */ `
  mutation DeletePresentedCarRecordSnapshot(
    $input: DeletePresentedCarRecordSnapshotInput!
    $condition: ModelPresentedCarRecordSnapshotConditionInput
  ) {
    deletePresentedCarRecordSnapshot(input: $input, condition: $condition) {
      id
      title
      dateUnsure
      description
      whoDidTheJob
      recordDate
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      owner
      noTime
      presentedcarsnapshotID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPresentedCarSnapshot = /* GraphQL */ `
  mutation CreatePresentedCarSnapshot(
    $input: CreatePresentedCarSnapshotInput!
    $condition: ModelPresentedCarSnapshotConditionInput
  ) {
    createPresentedCarSnapshot(input: $input, condition: $condition) {
      id
      year
      make
      carModel
      carVIN
      acquisitionDate
      acquisitionMileage
      odometerInMiles
      currentMileage
      color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      showMileage
      showInvestment
      showDoneBy
      showOverallDescription
      primaryRecordListMode
      overallDescription
      totalInvestment
      lastRecordMileage
      totalImagesCount
      totalRecordsCount
      carSnapshotsHaveRecordsSnapshots {
        nextToken
        startedAt
      }
      carID
      timeToDie
      highlightedRecords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePresentedCarSnapshot = /* GraphQL */ `
  mutation UpdatePresentedCarSnapshot(
    $input: UpdatePresentedCarSnapshotInput!
    $condition: ModelPresentedCarSnapshotConditionInput
  ) {
    updatePresentedCarSnapshot(input: $input, condition: $condition) {
      id
      year
      make
      carModel
      carVIN
      acquisitionDate
      acquisitionMileage
      odometerInMiles
      currentMileage
      color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      showMileage
      showInvestment
      showDoneBy
      showOverallDescription
      primaryRecordListMode
      overallDescription
      totalInvestment
      lastRecordMileage
      totalImagesCount
      totalRecordsCount
      carSnapshotsHaveRecordsSnapshots {
        nextToken
        startedAt
      }
      carID
      timeToDie
      highlightedRecords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePresentedCarSnapshot = /* GraphQL */ `
  mutation DeletePresentedCarSnapshot(
    $input: DeletePresentedCarSnapshotInput!
    $condition: ModelPresentedCarSnapshotConditionInput
  ) {
    deletePresentedCarSnapshot(input: $input, condition: $condition) {
      id
      year
      make
      carModel
      carVIN
      acquisitionDate
      acquisitionMileage
      odometerInMiles
      currentMileage
      color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      showMileage
      showInvestment
      showDoneBy
      showOverallDescription
      primaryRecordListMode
      overallDescription
      totalInvestment
      lastRecordMileage
      totalImagesCount
      totalRecordsCount
      carSnapshotsHaveRecordsSnapshots {
        nextToken
        startedAt
      }
      carID
      timeToDie
      highlightedRecords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      agreedToPolicy
      userEmail
      points
      premiumStatus
      userName
      owner
      imageCompressionRate
      likes {
        nextToken
        startedAt
      }
      hiddenPosts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      agreedToPolicy
      userEmail
      points
      premiumStatus
      userName
      owner
      imageCompressionRate
      likes {
        nextToken
        startedAt
      }
      hiddenPosts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      agreedToPolicy
      userEmail
      points
      premiumStatus
      userName
      owner
      imageCompressionRate
      likes {
        nextToken
        startedAt
      }
      hiddenPosts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCarRecords = /* GraphQL */ `
  mutation CreateCarRecords(
    $input: CreateCarRecordsInput!
    $condition: ModelCarRecordsConditionInput
  ) {
    createCarRecords(input: $input, condition: $condition) {
      id
      noTime
      isStory
      dateUnsure
      Title
      Description
      whoDidTheJob
      hoursSpent
      carID
      CarRecordsHasVerification {
        nextToken
        startedAt
      }
      recordDate
      recordDateTime
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      isArchived
      owner
      socialPost {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carRecordsSocialPostId
    }
  }
`;
export const updateCarRecords = /* GraphQL */ `
  mutation UpdateCarRecords(
    $input: UpdateCarRecordsInput!
    $condition: ModelCarRecordsConditionInput
  ) {
    updateCarRecords(input: $input, condition: $condition) {
      id
      noTime
      isStory
      dateUnsure
      Title
      Description
      whoDidTheJob
      hoursSpent
      carID
      CarRecordsHasVerification {
        nextToken
        startedAt
      }
      recordDate
      recordDateTime
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      isArchived
      owner
      socialPost {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carRecordsSocialPostId
    }
  }
`;
export const deleteCarRecords = /* GraphQL */ `
  mutation DeleteCarRecords(
    $input: DeleteCarRecordsInput!
    $condition: ModelCarRecordsConditionInput
  ) {
    deleteCarRecords(input: $input, condition: $condition) {
      id
      noTime
      isStory
      dateUnsure
      Title
      Description
      whoDidTheJob
      hoursSpent
      carID
      CarRecordsHasVerification {
        nextToken
        startedAt
      }
      recordDate
      recordDateTime
      CurrMileage
      picKeyList
      Cost
      coverPicKey
      isArchived
      owner
      socialPost {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carRecordsSocialPostId
    }
  }
`;
export const createCar = /* GraphQL */ `
  mutation CreateCar(
    $input: CreateCarInput!
    $condition: ModelCarConditionInput
  ) {
    createCar(input: $input, condition: $condition) {
      id
      Year
      Make
      CarModel
      carVIN
      Notes
      Metrics
      CarHasRecord {
        nextToken
        startedAt
      }
      acquisitionDate
      acquisitionDateTime
      acquisitionMileage
      odometerInMiles
      currentMileage
      Color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      PresentedCarSnapshots {
        nextToken
        startedAt
      }
      CarTodoItems {
        nextToken
        startedAt
      }
      isArchived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCar = /* GraphQL */ `
  mutation UpdateCar(
    $input: UpdateCarInput!
    $condition: ModelCarConditionInput
  ) {
    updateCar(input: $input, condition: $condition) {
      id
      Year
      Make
      CarModel
      carVIN
      Notes
      Metrics
      CarHasRecord {
        nextToken
        startedAt
      }
      acquisitionDate
      acquisitionDateTime
      acquisitionMileage
      odometerInMiles
      currentMileage
      Color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      PresentedCarSnapshots {
        nextToken
        startedAt
      }
      CarTodoItems {
        nextToken
        startedAt
      }
      isArchived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCar = /* GraphQL */ `
  mutation DeleteCar(
    $input: DeleteCarInput!
    $condition: ModelCarConditionInput
  ) {
    deleteCar(input: $input, condition: $condition) {
      id
      Year
      Make
      CarModel
      carVIN
      Notes
      Metrics
      CarHasRecord {
        nextToken
        startedAt
      }
      acquisitionDate
      acquisitionDateTime
      acquisitionMileage
      odometerInMiles
      currentMileage
      Color
      picKeyList
      transmissionType
      coverPicKey
      engine
      owner
      PresentedCarSnapshots {
        nextToken
        startedAt
      }
      CarTodoItems {
        nextToken
        startedAt
      }
      isArchived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVerification = /* GraphQL */ `
  mutation CreateVerification(
    $input: CreateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    createVerification(input: $input, condition: $condition) {
      id
      isVerified
      isVerifiable
      verifiedBy
      verifiedAt
      owner
      notes
      carRecordsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVerification = /* GraphQL */ `
  mutation UpdateVerification(
    $input: UpdateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    updateVerification(input: $input, condition: $condition) {
      id
      isVerified
      isVerifiable
      verifiedBy
      verifiedAt
      owner
      notes
      carRecordsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVerification = /* GraphQL */ `
  mutation DeleteVerification(
    $input: DeleteVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    deleteVerification(input: $input, condition: $condition) {
      id
      isVerified
      isVerifiable
      verifiedBy
      verifiedAt
      owner
      notes
      carRecordsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEventLog = /* GraphQL */ `
  mutation CreateEventLog(
    $input: CreateEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    createEventLog(input: $input, condition: $condition) {
      id
      actions
      createdAtTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEventLog = /* GraphQL */ `
  mutation UpdateEventLog(
    $input: UpdateEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    updateEventLog(input: $input, condition: $condition) {
      id
      actions
      createdAtTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEventLog = /* GraphQL */ `
  mutation DeleteEventLog(
    $input: DeleteEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    deleteEventLog(input: $input, condition: $condition) {
      id
      actions
      createdAtTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSocialPost = /* GraphQL */ `
  mutation CreateSocialPost(
    $input: CreateSocialPostInput!
    $condition: ModelSocialPostConditionInput
  ) {
    createSocialPost(input: $input, condition: $condition) {
      typ
      id
      title
      description
      owner
      picKeyList
      postedAt
      carRecords {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      likes {
        nextToken
        startedAt
      }
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      socialPostCarRecordsId
    }
  }
`;
export const updateSocialPost = /* GraphQL */ `
  mutation UpdateSocialPost(
    $input: UpdateSocialPostInput!
    $condition: ModelSocialPostConditionInput
  ) {
    updateSocialPost(input: $input, condition: $condition) {
      typ
      id
      title
      description
      owner
      picKeyList
      postedAt
      carRecords {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      likes {
        nextToken
        startedAt
      }
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      socialPostCarRecordsId
    }
  }
`;
export const deleteSocialPost = /* GraphQL */ `
  mutation DeleteSocialPost(
    $input: DeleteSocialPostInput!
    $condition: ModelSocialPostConditionInput
  ) {
    deleteSocialPost(input: $input, condition: $condition) {
      typ
      id
      title
      description
      owner
      picKeyList
      postedAt
      carRecords {
        id
        noTime
        isStory
        dateUnsure
        Title
        Description
        whoDidTheJob
        hoursSpent
        carID
        recordDate
        recordDateTime
        CurrMileage
        picKeyList
        Cost
        coverPicKey
        isArchived
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        carRecordsSocialPostId
      }
      likes {
        nextToken
        startedAt
      }
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      socialPostCarRecordsId
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      user {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      post {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      isDeleted
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDataLikesId
      socialPostLikesId
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      user {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      post {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      isDeleted
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDataLikesId
      socialPostLikesId
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      user {
        id
        agreedToPolicy
        userEmail
        points
        premiumStatus
        userName
        owner
        imageCompressionRate
        hiddenPosts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      post {
        typ
        id
        title
        description
        owner
        picKeyList
        postedAt
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        socialPostCarRecordsId
      }
      isDeleted
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userDataLikesId
      socialPostLikesId
    }
  }
`;
export const createSecureUserData = /* GraphQL */ `
  mutation CreateSecureUserData(
    $input: CreateSecureUserDataInput!
    $condition: ModelSecureUserDataConditionInput
  ) {
    createSecureUserData(input: $input, condition: $condition) {
      owner
      id
      credit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSecureUserData = /* GraphQL */ `
  mutation UpdateSecureUserData(
    $input: UpdateSecureUserDataInput!
    $condition: ModelSecureUserDataConditionInput
  ) {
    updateSecureUserData(input: $input, condition: $condition) {
      owner
      id
      credit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSecureUserData = /* GraphQL */ `
  mutation DeleteSecureUserData(
    $input: DeleteSecureUserDataInput!
    $condition: ModelSecureUserDataConditionInput
  ) {
    deleteSecureUserData(input: $input, condition: $condition) {
      owner
      id
      credit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

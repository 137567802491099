import React, { useEffect, useState } from 'react';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import "./DescriptionSection.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { faTimes, faRoad } from '@fortawesome/free-solid-svg-icons';


function TextContentComponent({ presentedCarSnapshotId, backend, isEdit }) {
  console.log("backend: ", backend);
  const [isExpanded, setIsExpanded] = useState(false); // State to track the expansion

  const [isCarInfoModalOpen, setIsCarInfoModalOpen] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);

  const [carDataUpdated, setCarDataUpdated] = useState(false);
  const [visibilityUpdated, setVisibilityUpdated] = useState(false);


  const handleOpenCarInfoModal = () => {
    setIsCarInfoModalOpen(true);
  };

  const handleCloseCarInfoModal = () => {
    setIsCarInfoModalOpen(false);
  };

  const handleOpenVisibilityModal = () => {
    setIsVisibilityModalOpen(true);
  };

  const handleCloseVisibilityModal = () => {
    setIsVisibilityModalOpen(false);
  };


  const toggleExpansion = () => {
    setIsExpanded(!isExpanded); // Toggle the state
  }

  useEffect(() => {
    if (backend) {
    }
  }, [backend, backend?.snapshot?.overallDescription, backend?.snapshot?.year, backend?.snapshot?.make, backend?.snapshot?.carModel]);

  // useEffect(() => {
  //   // if (backend) {
  //   //   window.location.reload();
  //   // }

  // }, [visibilityUpdated]);

  if (!backend) return null;


  return (
    <div className="text-section">
      <h1>For this {backend.snapshot.year} {backend.snapshot.make} {backend.snapshot.carModel},</h1>
      <h1><b>Papertrail has <span className="pt-green-text">{backend.getTotalHistoryCount()} histories with {backend.getTotalImagesCount()} photos</span>.</b></h1>
      {isEdit && (
        <div className='edit-car-info-btn-group'>
          <button className="edit-car-info-btn" onClick={handleOpenCarInfoModal}>
            Edit Information
          </button>

          <button className="edit-car-info-btn" onClick={handleOpenVisibilityModal}>
            Edit Visibility
          </button>
        </div>
      )}
      <div className="record-summary-group">

        {backend !== null && backend.showTotalInvestment &&
          <div className='record-summary'>
            <div className="icon-circle-border">
              <FontAwesomeIcon icon={faSackDollar} size="xl" />
            </div>
            <div className="record-summary-text">
              <span className='grey-text'><h4>Recorded Investment: </h4></span>
              <div className="detail-container">
                <p className="unit-sign">$</p>
                <h5 className="amount">{backend.getTotalInvestment()}</h5>
              </div>
            </div>
          </div>
        }

        {backend !== null && backend.showMileage && backend.showTotalInvestment && <div className="divider"></div>}

        {backend !== null && backend.showMileage &&
          <div className='record-summary'>
            <div className="icon-circle-border">
              <FontAwesomeIcon icon={faRoad} size="xl" />
            </div>
            <div className="record-summary-text">
              <span className='grey-text'><h4>Last recorded at: </h4></span>
              <div className="detail-container">
                <h5 className="amount">{backend.getLastRecordMileage()}</h5>
                <p className="unit-sign">{backend.getMileageUnit()}</p>
              </div>
            </div>
          </div>
        }
      </div>


      {backend !== null && backend.showOverallDescription &&
        <div className="text-description-group">
          <h3>Overall Description</h3>
          <div className={`overall-description ${isExpanded ? 'expanded' : ''}`}>
            {backend.getOverallDescription()}
          </div>
          <button onClick={toggleExpansion}>
            {isExpanded ? 'Collapse' : 'Expand'}
          </button>
        </div>
      }


      {isEdit && <EditCarInformationModal
        backend={backend}
        isOpen={isCarInfoModalOpen}
        onClose={handleCloseCarInfoModal}
        onDataUpdate={() => setCarDataUpdated(!carDataUpdated)}
      />}

      {isEdit && <EditVisibilityOptionsModal
        backend={backend}
        isOpen={isVisibilityModalOpen}
        onClose={handleCloseVisibilityModal}
        onDataUpdate={() => setVisibilityUpdated(!visibilityUpdated)}
      />}

    </div>

  );
}


function EditCarInformationModal({ backend, isOpen, onClose, onDataUpdate }) {
  const [formData, setFormData] = useState({
    year: '',
    make: '',
    carModel: '',
    overallDescription: '',
    // Add other form fields as needed
  });


  useEffect(() => {
    if (isOpen && backend) {
      // Initialize form data with current values
      setFormData({
        year: backend.snapshot.year || '',
        make: backend.snapshot.make || '',
        carModel: backend.snapshot.carModel || '',
        overallDescription: backend.getOverallDescription() || '',

      });
    }
  }, [isOpen, backend]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    // Send updated data to backend
    await backend.updateCarSnapshotInformation(formData);
    onClose(); // Close modal after submission
    if (onDataUpdate) onDataUpdate(); // Check and call the function
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <FontAwesomeIcon icon={faTimes} className="modal-close-btn" onClick={onClose} />
        <div className="modal-form">
          <label htmlFor="year">Year</label>
          <input
            type="text"
            id="year"
            name="year"
            value={formData.year || ''}
            onChange={handleChange}
            placeholder="Year"
          />

          <label htmlFor="make">Make</label>
          <input
            type="text"
            id="make"
            name="make"
            value={formData.make || ''}
            onChange={handleChange}
            placeholder="Make"
          />

          <label htmlFor="carModel">Model</label>
          <input
            type="text"
            id="carModel"
            name="carModel"
            value={formData.carModel || ''}
            onChange={handleChange}
            placeholder="Model"
          />

          <label htmlFor="overallDescription">Overall Description</label>
          <textarea
            id="overallDescription"
            name="overallDescription"
            value={formData.overallDescription || ''}
            onChange={handleChange}
            placeholder="Overall Description"
          />

        </div>
        <button className="save-changes-btn" onClick={handleSubmit}>Save Changes</button>

      </div>
    </div>
  );
}


function EditVisibilityOptionsModal({ backend, isOpen, onClose, onDataUpdate }) {
  const [formData, setFormData] = useState({
    showTotalInvestment: false,
    showMileage: false,
    showDoneByForEachRecord: false,
    showOverallDescription: false,
  });

  useEffect(() => {
    if (isOpen && backend) {
      // Initialize form data with current values from backend
      setFormData({
        showTotalInvestment: backend.showTotalInvestment,
        showMileage: backend.showMileage,
        showDoneByForEachRecord: backend.showDoneByForEachRecord,
        showOverallDescription: backend.showOverallDescription,
      });
    }
  }, [isOpen, backend]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async () => {
    // Send updated data to backend
    await backend.updateVisibilityOptions(formData);
    onClose(); // Close modal after submission
    if (onDataUpdate) onDataUpdate(); // Check and call the function
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <FontAwesomeIcon icon={faTimes} className="modal-close-btn" onClick={onClose} />
        <div className="modal-form">
          <label htmlFor="showTotalInvestment">
            <input
              type="checkbox"
              id="showTotalInvestment"
              name="showTotalInvestment"
              checked={formData.showTotalInvestment}
              onChange={handleChange}
            />
            Show Total Investment
          </label>

          <label htmlFor="showMileage">
            <input
              type="checkbox"
              id="showMileage"
              name="showMileage"
              checked={formData.showMileage}
              onChange={handleChange}
            />
            Show Mileage
          </label>

          <label htmlFor="showDoneByForEachRecord">
            <input
              type="checkbox"
              id="showDoneByForEachRecord"
              name="showDoneByForEachRecord"
              checked={formData.showDoneByForEachRecord}
              onChange={handleChange}
            />
            Show Done By For Each Record
          </label>

          <label htmlFor="showOverallDescription">
            <input
              type="checkbox"
              id="showOverallDescription"
              name="showOverallDescription"
              checked={formData.showOverallDescription}
              onChange={handleChange}
            />
            Show Overall Description of the Car
          </label>

        </div>
        <button className="save-changes-btn" onClick={handleSubmit}>Save Changes</button>
      </div>
    </div>
  );
}


export default TextContentComponent;
import React from 'react'
import './ImageSlider.css'
const ImageSlider = ({ images, currentIndex, onClick, width, centered }) => {
  return (
    <div className="scroller-container" style={{ justifyContent: centered ? 'center' : 'flex-start' }}>
      {images.map((image, index) => (
        index === currentIndex ?
          <img className="scroller-image current-image" src={image.url} alt={`Car ${index}`} key={index}/>
          : <img className="scroller-image" src={image.url} alt={`Car ${index}`} key={index} onClick={() => onClick(index)} />
      ))}
    </div>
  )
}

export default ImageSlider
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "9d0f703b433a43fea3b9320eb3391079",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9d0f703b433a43fea3b9320eb3391079",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "9d0f703b433a43fea3b9320eb3391079",
                "region": "us-east-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://7bwlaxslhbbc3lheksrivjpita.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fnoaholqnnbfvkz5o22bv25xua",
    "aws_cloud_logic_custom": [
        {
            "name": "chatgptCreditCheckerAPI",
            "endpoint": "https://r9y81fckui.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "feedbackEmailSenderAPI",
            "endpoint": "https://6oktd298ej.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "notifications",
            "endpoint": "https://hvtjkz09be.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "openaiRequestSenderAPI",
            "endpoint": "https://usio52rn5m.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "presentationDescWriterAPI",
            "endpoint": "https://86md1v2i7e.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "transferEmailSender",
            "endpoint": "https://400c78ok15.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        },
        {
            "name": "transferExecutor",
            "endpoint": "https://wsn3z83d55.execute-api.us-east-1.amazonaws.com/papertrail",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:717ff547-584d-4435-8831-4434a8a5b40e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PPbLK4gco",
    "aws_user_pools_web_client_id": "6kk36130t9t9gifc0dhpe9ccle",
    "oauth": {
        "domain": "ao3oel4v9732-papertrail.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "papertrail://",
        "redirectSignOut": "papertrail://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "papertrail-one131957-papertrail",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

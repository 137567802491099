import * as React from "react";
import Carousel from 'react-material-ui-carousel'
import { Storage } from 'aws-amplify';
import { useEffect, useState } from "react";
import NewSlider from './newSlider.jsx';

const DISPLAY_NO_IMAGE = (
  <svg xmlns="http://www.w3.org/2000/svg" className="empty-image">
    <rect width="100%" height="100%" fill="#808080"/>
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#ffffff">No Image</text>
  </svg>
);

function mapURLToImage(url, props) {
  return (
    <div className="slide-background" key={url}>
        <img className="slideshow-item" src={url} alt="Car" />
    </div>
  )
}

async function getImageURLs(imageKeyArray, setKeyToURL) {
  const promises = imageKeyArray.map(async (key) => {
    const url = await Storage.get(key, { expires: 3600, level: 'public', download: false, contentType: 'image/jpeg' });
    return [key, url];
  });

  const keyValuePairs = await Promise.all(promises);
  const updatedKeyToURL = keyValuePairs.reduce((acc, [key, url]) => ({ ...acc, [key]: url }), {});
  setKeyToURL(prevState => ({...prevState, ...updatedKeyToURL}));
}

const Slider = (props) => {
  console.log(props)

  const imageKeyArray = props.picKeyList.split(",");
  console.log("ImageKeyArray", imageKeyArray)
  const [keyToURL, setKeyToURL] = React.useState({});

  useEffect(() => {
      getImageURLs(imageKeyArray, setKeyToURL);
  }, []);


  const [images, setImages] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {

        const imageList = await Promise.all(
          imageKeyArray.map(async (key) => {
            const image = await Storage.get(key, { expires: 3600, level: 'public', download: false, contentType: 'image/jpeg' });
            return {
              key,
              url: image,
            };
          })
        );
        setImages(imageList);

      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, []);


  return (
    <div>
    {!props.picKeyList || props.picKeyList === "" ? DISPLAY_NO_IMAGE :
    <NewSlider images={images.map((image) => (
                  image.url
            ))} />}
    </div>
    
    
  );
};

export default Slider;
// simple react component tha renders a div with a checkmark, some text, and a button to download an app
import * as React from "react";
import "./Transfer.css";
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import appleStoreImage from './Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import googlePlayImage from './google-play-badge.png';

const TransferSuccess = (props) => {
  return (
    <div className="statusContainer">
        <div className="checkmark">
            <CheckIcon sx={{ fontSize: 150 }} />
        </div>
        <div className="transfer-success-text">
            <div className="transfer-success-text-header">Transfer Successful</div>
            <div className="transfer-success-text-body">Your car has been successfully transferred.</div>
        </div>
        <div className="email-key">
            {/* <Button className="email-key" variant="contained">Download App</Button> */}
            <div class="button-container">
                <a href="https://apps.apple.com/us/app/6444321445" target="_blank">
                    <img src={appleStoreImage} alt="Download on the App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.HGI.papertrail_one" target="_blank">
                    <img src={googlePlayImage} alt="Get it on Google Play" />
                </a>
            </div> 
        </div>
        {/* <div className="email-key">
            <Button className="email-key" variant="outlined">OK</Button>
        </div> */}
        
    </div>
    );
};
export default TransferSuccess;

// // PhotoCarouselComponent.js

import React, { useEffect, useState } from 'react';
import PresentedCarBackend from '../../../backend/PresentedCarBackend.js';
import { API, Storage, graphqlOperation } from "aws-amplify";
import "../DescriptionSection.css";
import ImageCarousel from './ImageCarousel.jsx';
import placeholderImage from '../../resources/pt_logo.png'


function PhotoCarouselForRecords({ presentedCarSnapshotId, recordImageKeys }) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const imageKeys = recordImageKeys;
        // console.log("image keys: ");
        // console.log(imageKeys);
        // console.log("record image keys: ");
        // console.log(recordImageKeys);

        if (imageKeys.length === 1 && imageKeys[0] === placeholderImage) {
            console.log("Setting placeholder image")
            setImages([{
                url: placeholderImage,
            }]);
        }

        else {
            // Fetch URLs for each image key
            // console.log(imageKeys)
            Promise.all(imageKeys.map(async (key) => {
                const imageUrl = await Storage.get(key, {
                    expires: 3600,
                    level: "public",
                    download: false,
                    contentType: "image/jpeg",
                });

                return {
                    key,
                    url: imageUrl,
                };
            }))
                .then(fetchedImages => {
                    setImages(fetchedImages);
                });
        }

    }, [presentedCarSnapshotId]);

    return (
        <div className="photo-carousel-section">
            <ImageCarousel images={images} aspectRatio='1:1' maxWidth='200px'/>
        </div>
    );
}

export default PhotoCarouselForRecords;
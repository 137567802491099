// DescriptionComponent.js

import React, { useEffect, useState } from 'react';
import PresentedCarBackend from '../../backend/PresentedCarBackend.js';
import TextContentComponent from './TextContentComponent.js';
import PhotoCarouselComponent from './ImageCarousel/PhotoCarouselComponent.js';
import "./DescriptionSection.css";

function DescriptionComponent({ presentedCarSnapshotId, backend, images, isEdit}) {
    console.log("backend: ", backend);
    return (
        <div className="description-section">
            <TextContentComponent presentedCarSnapshotId={presentedCarSnapshotId} backend={backend} isEdit={isEdit}/>
            <PhotoCarouselComponent presentedCarSnapshotId={presentedCarSnapshotId} backend={backend} images={images}/>
        </div>
    );
}

export default DescriptionComponent;

import React, { Component } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import "../../slider/Slider.css";
import { DataStore } from "aws-amplify";
import { PresentedCarSnapshot } from "../../../models";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";
import NewSlider from "../../slider/newSlider.jsx";
import { Hub } from "@aws-amplify/core";

const ListImagesQuery = `
  query ListImages {
    listImages {
      items {
        id
        imageKeys
      }
    }
  }
`;

class PresentationTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      images: [],
      presentedCarSnapshots: [],
    };
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  async componentDidMount() {
    DataStore.start();
    const { presentedCarSnapshotId } = this.props;

    const listener = Hub.listen('datastore', async hubData => {
      const { event, data } = hubData.payload;
      if (event === 'ready') {
        try {
          const presentedCarSnapshots = await DataStore.query(
            PresentedCarSnapshot,
            presentedCarSnapshotId
          );
          const keys = presentedCarSnapshots.picKeyList
            .split(",")
            .filter((key) => key);

          const imageList = await Promise.all(
            keys.map(async (key) => {
              const image = await Storage.get(key, {
                expires: 3600,
                level: "public",
                download: false,
                contentType: "image/jpeg",
              });
              return {
                key,
                url: image,
              };
            })
          );
          this.setState({
            images: imageList,
            presentedCarSnapshots: presentedCarSnapshots,
            loading: false,
          });
        } catch (err) {
          console.log("error: ", err);
        }
      }
    })

  }

  render() {
    const { loading, images, presentedCarSnapshots } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="vehicleContainer">
          <div className="vehiclePhotos">
            <NewSlider
              images={images.map((image) => image.url)}
            />
          </div>
          <div className="vehicleDetails">
            <h1>
              {presentedCarSnapshots.year} {presentedCarSnapshots.make}{" "}
              {presentedCarSnapshots.carModel}
            </h1>
            <p>VIN: {presentedCarSnapshots.carVIN}</p>
            <p>Engine: {presentedCarSnapshots.engine}</p>
            <p>Transmission: {presentedCarSnapshots.transmissionType}</p>
            <p>Color: {presentedCarSnapshots.color}</p>
          </div>
        </div>
      );
    }
  }
}

export default PresentationTop;
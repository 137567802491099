import React, { useEffect, useState } from 'react';
import { API, Storage, graphqlOperation } from "aws-amplify";
import ImageCarousel from '../presentation/ImageCarousel/ImageCarousel.jsx';
import placeholderImage from '../resources/pt_logo.png'

const PhotoCarouselForCarPreview = ({presentedCarSnapshotId, recordImageKeys}) => {


    const [images, setImages] = useState([]);

    useEffect(() => {
        // const presentedCarBackend = new PresentedCarBackend(presentedCarSnapshotId);
        // presentedCarBackend.initialize().then(() => {
            // const imageKeys = presentedCarBackend.getCarImageList();
            const imageKeys = recordImageKeys;
            // console.log("image keys: ");
            // console.log(imageKeys);
            // console.log("record image keys: ");
            // console.log(recordImageKeys);

            if (imageKeys.length === 1 && imageKeys[0] === placeholderImage) {
                console.log("Setting placeholder image")
                setImages([{
                    url: placeholderImage,
                }]);
            }

            else {
                // Fetch URLs for each image key
                // console.log(imageKeys)
                Promise.all(imageKeys.map(async (key) => {
                    const imageUrl = await Storage.get(key, {
                        expires: 3600,
                        level: "public",
                        download: false,
                        contentType: "image/jpeg",
                    });

                    return {
                        key,
                        url: imageUrl,
                    };
                }))
                    .then(fetchedImages => {
                        setImages(fetchedImages);
                    });
            }

        // }
        // );
    }, [presentedCarSnapshotId]);


  return (
    <div>
        {/* {presentedCarSnapshotId}
        {recordImageKeys} */}
        <div className="photo-carousel-section">
            <ImageCarousel images={images} aspectRatio='1:1' maxWidth='200px'/>
            
        </div>
    
    </div>
  )
}

export default PhotoCarouselForCarPreview